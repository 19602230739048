<template>
  <svg width="566" height="150" viewBox="0 0 566 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M429.266 36.4705C422.477 25.0774 413.132 16.1521 401.23 9.67845C389.313 3.22081 375.958 0 361.133 0H204.5C203.925 0 203.35 -1.16073e-06 202.791 0.0160228C188.653 0.256382 175.857 3.46117 164.419 9.67845C152.518 16.1521 143.172 25.1095 136.383 36.5666C129.594 48.0237 126.207 60.8268 126.207 75.008C126.207 89.1892 129.61 101.992 136.383 113.449C143.172 124.907 152.518 133.864 164.419 140.322C175.873 146.539 188.669 149.744 202.791 149.984C203.366 150 203.941 150 204.5 150H361.133C375.942 150 389.313 146.779 401.23 140.322C413.132 133.864 422.477 124.939 429.266 113.546C436.056 102.153 439.442 89.3014 439.442 75.008C439.442 60.7147 436.056 47.8475 429.266 36.4705ZM405.576 101.367C401.151 109.219 395.048 115.324 387.3 119.699C379.537 124.073 370.814 126.269 361.133 126.269H204.5C194.803 126.269 186.081 124.073 178.333 119.699C170.585 115.324 164.483 109.219 160.042 101.367C155.617 93.5156 153.396 84.7345 153.396 75.008C153.396 65.2815 155.617 56.5004 160.042 48.6486C164.483 40.8129 170.569 34.6918 178.333 30.3173C186.081 25.9427 194.819 23.7635 204.5 23.7635H361.133C370.814 23.7635 379.537 25.9427 387.3 30.3173C395.048 34.6918 401.151 40.8129 405.576 48.6486C410.001 56.5004 412.221 65.2975 412.221 75.008C412.221 84.7185 410.001 93.5156 405.576 101.367Z" fill="white"/>
    <path d="M110.934 118.275H91.65V150H65.9382V118.275H0V98.7838L66.5603 1.53528H91.65V96.9176H110.934V118.275ZM65.9382 63.7412C65.9382 60.8382 65.9382 57.8662 65.9382 54.825C66.0765 51.6456 66.2147 48.5353 66.3529 45.4941C66.4912 42.4529 66.6294 39.6882 66.7676 37.2C66.9059 34.7117 67.0441 32.7765 67.1824 31.3941H66.3529C65.1088 34.0206 63.7265 36.7853 62.2059 39.6882C60.8235 42.4529 59.2338 45.0794 57.4368 47.5676L23.8456 96.9176H65.9382V63.7412Z" fill="white"/>
    <path d="M565.346 118.275H546.062V150H520.35V118.275H454.412V98.7838L520.972 1.53528H546.062V96.9176H565.346V118.275ZM520.35 63.7412C520.35 60.8382 520.35 57.8662 520.35 54.825C520.489 51.6456 520.627 48.5353 520.765 45.4941C520.903 42.4529 521.042 39.6882 521.18 37.2C521.318 34.7117 521.456 32.7765 521.594 31.3941H520.765C519.521 34.0206 518.139 36.7853 516.618 39.6882C515.236 42.4529 513.646 45.0794 511.849 47.5676L478.258 96.9176H520.35V63.7412Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'Svg404'
}
</script>  