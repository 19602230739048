<template>
    <svg :width="width" :height="height" :viewBox="`0 0 16 12`" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.17167 8.01264L6.17118 3.65503L10.3741 5.84135L6.17167 8.01264ZM15.4 2.89992C15.4 2.89992 15.2478 1.83124 14.7817 1.36063C14.1901 0.742683 13.527 0.739769 13.2232 0.703904C11.0464 0.546873 7.78118 0.546875 7.78118 0.546875H7.77438C7.77438 0.546875 4.50917 0.546873 2.33236 0.703904C2.02806 0.739769 1.36549 0.742683 0.773404 1.36063C0.307224 1.83124 0.155554 2.89992 0.155554 2.89992C0.155554 2.89992 0 4.1552 0 5.41V6.58676C0 7.84204 0.155554 9.09684 0.155554 9.09684C0.155554 9.09684 0.307224 10.1655 0.773404 10.6361C1.36549 11.2541 2.14278 11.2347 2.48889 11.2991C3.73333 11.4184 7.77778 11.4552 7.77778 11.4552C7.77778 11.4552 11.0464 11.4504 13.2232 11.2933C13.527 11.257 14.1901 11.2541 14.7817 10.6361C15.2478 10.1655 15.4 9.09684 15.4 9.09684C15.4 9.09684 15.5556 7.84204 15.5556 6.58676V5.41C15.5556 4.1552 15.4 2.89992 15.4 2.89992Z" :fill="color"/>
    </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 14
    },
    width: {
      type: Number,
      default: 16
    },
    color: {
      type: String,
      default: '#FFFFFF'
    }
  }
}
</script>