import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/scss/main.scss'
import CONSTANTS from '@/constants/index'
import VueGtag from 'vue-gtag'



const app = createApp(App)
app.config.globalProperties.$const = CONSTANTS
app
  .use(store)
  .use(router)
  .use(VueGtag, {
    config: { id: 'G-GBCGEW98DX' },
    enabled: true
  })
  .mount('#app')
