<template>
	<div class="c-select">
		<select @input="handleInput" :name="label" :id="label" class="c-select__select">
      <option value="" selected>{{ label }}</option>
			<template v-if="type === 'subYear'">
				<option v-for="(item, id) in items" :key="id" :value="item">{{ item }}</option>
			</template>
			<template v-else>
				<option v-for="option in value" :key="option.name" :value="option.id">{{ option.name }}</option>
			</template>
    </select>
    <span class="c-select__icon icon-chevron-right"></span>
	</div>
</template>

<script>
export default {
	name: 'Select',
	props: {
		label: {
			type: String,
			required: true
		},
    value: {
			type: Object,
      default: () => ({}),
			required: true
		},
		type: {
			type: String,
			default: ''
		}
	},
	computed: {
		items () {
			return [...new Set(this.value.map(val => val.name.substring(3)))]
		}
	},
  methods: {
    handleInput (e) {
      this.$emit('setItem', e.srcElement.value)
    }
  }
}
</script>