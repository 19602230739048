<template>
  <div class="c-socials" :class="{'c-socials--dark' : dark}">
    <a v-if="socials.socials_youtube" :href="socials.socials_youtube" target="_blank" class="c-socials__item">
      <YoutubeVector
          :color="colorType"
      />
    </a>
    <a v-if="socials.socials_twitter" :href="socials.socials_twitter" target="_blank" class="c-socials__item">
      <TwitterVector
          :color="colorType"
      />
    </a>
    <a v-if="socials.socials_linkedin" :href="socials.socials_linkedin" target="_blank" class="c-socials__item">
      <LinkedInVector
          :color="colorType"
      />
    </a>
  </div>
</template>

<script>
import YoutubeVector from './vector/YoutubeVector.vue'
import TwitterVector from './vector/TwitterVector.vue'
import LinkedInVector from './vector/LinkedInVector.vue'

export default {
  name: 'Socials',
  components: {
    YoutubeVector,
    TwitterVector,
    LinkedInVector,
  },
  props: {
    dark: {
        type: Boolean,
        default: true
    },
    socials: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
      return {
          items: ''
      }
  },
  computed: {
    colorType() {
      return this.dark ? '#FFFFFF' : '#0009DE'
    }
  }
}
</script>