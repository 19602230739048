<template>
  <div 
    class="c-dropdown"
  >
    <div class="c-dropdown__head" :class="{'c-dropdown__head--active' : toggle}" @click="toggleDropdown">
      <span class="c-dropdown__head__title h3-like">
        {{ dropdown.real_dropdown_title }}
      </span>
      <span class="c-dropdown__head__icon icon-chevron-right"></span>
    </div>
    <div class="c-dropdown__content editor-block" v-html="dropdown.real_dropdown_content">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    dropdown: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      toggle: false
    }
  },
  methods: {
    toggleDropdown() {
      let content = this.$el.querySelector('.c-dropdown__content');

      this.toggle = !this.toggle;
      this.toggle
        ? content.style.height = `${content.scrollHeight}px`
        : content.style.height = 0;
    }
  }
}
</script>