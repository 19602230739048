<template>
  <div class="c-prefooter background background-animated">
    <h2 v-if="title" class="c-prefooter__title">
      {{ title }}
    </h2>
    <div v-if="content" class="c-prefooter__content" v-html="content">
    </div>
  </div>
</template>

<script>

export default {
  name: 'Prefooter',
  props: {
    title: {
      type: String, 
      default: ''
    },
    content: {
      type: String, 
      default: ''
    },
  }
}
</script>