<template>
  <div class="c-single">
    <Banner
      :image="getMedia(realisation.featured_media, realisation._embedded?.['wp:featuredmedia']).source_url"
      :context="$const.COMMONS.BANNER_CONTEXT_POST_DETAILS"
      :copyright="realisation.acf.thumbnail_copyright"
    />
    <Intro :post="realisation" :context="$const.COMMONS.ARCHIVE_CONTEXT_REALISATIONS"/>

    <div class="c-single__container">
      <div class="c-single__content">
        <h3 class="c-title-section">Retour en image</h3>
      </div>

      <div v-if="realisation.acf && realisation.acf.real_gallery.length > 0" class="c-single__content">
        <div class="carousel__wrapper">
          <ImageCarousel
            :slides="realisation.acf.real_gallery"
          />
        </div>
      </div>

      <div v-if="realisation.acf && realisation.acf.real_dropdown.length" class="c-single__content">
        <div class="container">
          <div class="c-dropdown__container">
            <Dropdown 
              v-for="(dropdown, index) in realisation.acf.real_dropdown" 
              :key="index"
              :dropdown="dropdown" 
            />
          </div>
        </div>
      </div>

      <div v-if="realisation.acf && realisation.acf.real_echelle_title" class="c-single__content">
        <ContentImage
          :title="realisation.acf.real_echelle_title"
          :content="realisation.acf.real_echelle_content"
          :cta="realisation.acf.real_echelle_cta"
          :image="realisation.acf.real_echelle_image?.url"
        >
          <div class="c-echelle">
            <div class="c-echelle__container">
              <div 
                v-for="nb, index in echelle" 
                :key="index"
                :class="{'c-echelle__item--active' : realisation.acf.real_echelle_nb.find(item => item === nb ) }"
                class="c-echelle__item">
                <div class="c-echelle__item__index__container">
                  <div class="c-echelle__item__index">{{ index }}</div>
                </div> {{ nb }}
              </div>
            </div>
          </div>
        </ContentImage>
      </div>

    </div>

  </div>
</template>

<script>
import Banner from '@/components/Banner.vue';
import ImageCarousel from '@/components/globals/carousel/ImageCarousel.vue';
import Dropdown from '@/components/globals/Dropdown.vue';
import Intro from '@/components/Intro.vue';
import { mapState } from 'vuex';
import ContentImage from '@/components/globals/ContentImage.vue';
import { useMedia } from '@/composables/use-media-logic'

export default {
  name: "SingleRealisation",
  setup () {
    const { getMedia } = useMedia()

    return {
      getMedia
    }
  },
  components: { Banner, Intro, ImageCarousel, Dropdown, ContentImage },
  data () {
    return {
      echelle: [
        'Idée',
        'Recherche basique',
        'Formulation de la technologie',
        'Besoin de validation',
        'Prototype echelle réduite',
        'Prototype taille (quasi) réelle',
        'Prototypage du système',
        'Démonstrateur',
        'Système complet et qualifié',
        'Système commercialisé'
      ]
    }
  },
  computed: {
    ...mapState('realisations', ['realisations']),
    slides () {
      if (this.realisation && this.realisation.acf && this.realisation.acf.real_gallery) {
        return this.realisation.acf.real_gallery.map(id => this.getMedia(id, this.realisation['_embedded']['acf:attachment']))
      }
      return []
    },

    realisation() {
      if (this.realisations && this.realisations.length) {
        return this.realisations.find(item => item.slug === this.$route.params.slug)
      }
      return {}
    }
  }
}
</script>