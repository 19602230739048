<template>
  <div>
    <h1>Admin</h1>
  </div>
</template>

<script>
export default {
  name: 'Admin',
  created() {
    window.location.href = 'http://backrol.buchs.cool/wp-admin'
  }
}
</script>