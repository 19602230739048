<template>
  <div v-if="company" class="c-company-presentation__overlay">
    <div class="c-company-presentation__overlay__content">
      <div class="c-company-presentation__overlay__content__visual">
        <img :src="imgURL(company)" :alt="company.title.rendered">
      </div>
      <div class="c-company-presentation__overlay__content__text">
        <span class="c-company-presentation__overlay__content__text__title">{{ company.title.rendered }}</span>
        <div class="editor-block" v-html="company.content.rendered"></div>
        <Cta
          label="Devenir Partenaire"
          type="action"
          @click:button="$router.push('/devenir-partenaire')"
        />
      </div>
    </div>
    <div
      class="c-company-presentation__overlay__close"
      @click="$emit('resetCurrentCompany')"
    >
    </div>
  </div>
</template>

<script>
import Cta from '@/components/globals/Cta'

export default {
  name: 'CompanyPresentationOverlay',
  components: {
    Cta
  },
  props: {
    company: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  mounted () {
    setTimeout(() => {
      this.$el.classList.add('c-company-presentation__overlay--active')
    }, 10);
  },
  methods: {
    imgURL (item) {
      const media = item._embedded['wp:featuredmedia']
      if (media) return media[0].source_url
      return ''
    }
  }
}
</script>

