<template>
  <div>
   <div class="c-not-found background background-animated">
    <div class="c-not-found__content">
      <Svg404 />
      <h1 class="c-not-found__content__title h2-like">Page non trouvée</h1>

      <router-link
        to="/"
        class="cta filled cta--white"
      >
        <span>
          Retour à l'accueil
        </span>
      </router-link>
    </div>
   </div>
  </div>
</template>

<script>
import Svg404 from '@/components/vector/Svg404.vue';

export default {
  name: 'NotFound',
  components: { Svg404 }
}
</script>