<template>
	<div v-if="label && label.acf" class="c-label-item">
    <div class="c-label-item__container">
      <div class="c-label-item__content">
        <span v-if="label.title.rendered" class="c-label-item__content__title">
          {{ label.title.rendered }}
        </span>
        <span v-for="category, index in label['labels_categories']" :key="index" class="c-label-item__content__category">
          {{ getCategories(category) }}
        </span>
        <span v-if="label.acf.label_subtitle" class="c-label-item__content__subtitle">
          {{ label.acf.label_subtitle }}
        </span>
        <p v-if="label.acf.label_text" class="c-label-item__content__text">
          {{ label.acf.label_text }}
        </p>
        <a 
          v-if="label.acf.label_link && label.acf.label_link.url" 
          :href="label.acf.label_link.url" 
          target="_blank"
          class="c-label-item__content__link"
        >
          {{ label.acf.label_link.title }}
        </a>
      </div>
      <div v-if="label.featured_media" class="c-label-item__visual">
        <img :src="getMedia(label.featured_media, label._embedded['wp:featuredmedia']).source_url" alt=""  class="c-label-item__visual__img" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { useMedia } from '@/composables/use-media-logic';

export default {
	name: 'LabelItem',
  setup() {
    const { getMedia } = useMedia()
    return { getMedia }
  },
  props: {
    label: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState('labels', ['labels_categories']),
  },
  methods: {
    getCategories (id) {
      return this.labels_categories.find(item => item.id === id)?.name
    },
  }
}
</script>