import PAGES from "@/constants/pages";
import httpClient from "@/utils/httpClient";

const pages = {
  namespaced: true,
  state: {
    data: {}
  },
  getters: {
    getData(state) {
      return state.data
    }
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data
    }
  },
  actions: {
    async setPageBySlug({commit}, slug) {
      const response = await httpClient.get(`${PAGES.GET_PAGES}?slug=${slug}`)
      commit('SET_DATA', response[0])
    },
    resetPage ({commit}) {
      commit('SET_DATA', {})
    }
  }
}

export default pages
