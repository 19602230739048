<template>
    <svg :width="width" :height="height" :viewBox="`0 0 16 14`" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" :width="width" :height="height" clip-rule="evenodd" d="M15.7777 2.10371C15.2062 2.3614 14.5908 2.53566 13.9452 2.61352C14.6045 2.21309 15.1103 1.57814 15.3486 0.821769C14.7314 1.19254 14.0493 1.46227 13.3207 1.60687C12.74 0.977489 11.9101 0.584473 10.9915 0.584473C9.23027 0.584473 7.80136 2.03512 7.80136 3.82409C7.80136 4.07807 7.82875 4.32463 7.88353 4.56193C5.23113 4.4266 2.88004 3.13816 1.30504 1.17585C1.03021 1.656 0.873164 2.21309 0.873164 2.80632C0.873164 3.92976 1.43652 4.92158 2.29296 5.50276C1.77069 5.487 1.27765 5.33962 0.846689 5.09862V5.13847C0.846689 6.70869 1.94691 8.01844 3.40778 8.31506C3.14026 8.39107 2.85813 8.42907 2.56687 8.42907C2.36143 8.42907 2.16056 8.40961 1.96608 8.3716C2.37239 9.65818 3.55021 10.5953 4.94717 10.6203C3.85517 11.4898 2.4783 12.0079 0.983645 12.0079C0.726166 12.0079 0.471429 11.9931 0.222168 11.9635C1.63465 12.8811 3.31283 13.4178 5.11426 13.4178C10.9851 13.4178 14.1945 8.48191 14.1945 4.20042C14.1945 4.05953 14.1918 3.91864 14.1863 3.78052C14.8099 3.32355 15.3513 2.75349 15.7777 2.10371Z" :fill="color"/>
    </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 14
    },
    width: {
      type: Number,
      default: 16
    },
    color: {
      type: String,
      default: '#FFFFFF'
    }
  }
}
</script>