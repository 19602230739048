<template>
  <div class="c-team">
    <h2 class="c-team__title">
      {{ title }}
    </h2>
    <div v-if="content" class="c-team__text editor-block" v-html="content"></div>
    <div class="c-team__container">
      <div v-for="member,index in members" :key="index" class="c-team__item">
        <div class="c-team__item__visual">
          <img :src="member.image.url" alt="" class="c-team__item__visual__img" />
          <p class="c-team__item__visual__copyright">{{ member.copyright  }}</p>
        </div>
        <span class="c-team__item__subtitle">{{ member.position }}</span>
        <span class="c-team__item__title">{{ member.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Team",
    props: {
      title: {
        type: String,
        required: true
      },
      content: {
        type: String,
        default: ''
      },
      members: {
        type: Array,
      }
    }
  }
</script>