<template>
  <div class="analytics">
    <div class="analytics__modal">
      <div class="analytics__modal__content">
        <h2>Votre configuration des cookies</h2>
        <p>Avec votre accord, nous utilisons des cookies ou technologies similaires pour stocker et accéder à des informations personnelles comme votre visite sur ce site.</p>
        <p>Nous avons recours aux cookies pour :</p>
        <ul>
          <li>Garantir la sécurité et les performances du site</li>
          <li>Adapter la présentation de nos services à votre Terminal</li>
          <li>Permettre de visionner des vidéos directement sur le site</li>
          <li>Améliorer notre site internet</li>
        </ul>
        <p>Pour plus d'informations sur les cookies, cliquez <router-link to="/mentions-legales">ici</router-link> pour lire notre politique de confidentialité. </p>
      </div>
      <div class="analytics__modal__footer">
        <button @click="$emit('enable:analytics')">Accepter</button>
        <button @click="$emit('disable:analytics')">Refuser</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalAnalytics'
}
</script>