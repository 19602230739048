import { createStore } from 'vuex'
import pages from './modules/pages'
import posts from './modules/posts'
import loader from './modules/loader'
import realisations from './modules/realisations'
import companys from './modules/companys'
import labels from './modules/labels'

const store = createStore({
  modules: {
    pages: pages,
    posts: posts,
    loader: loader,
    realisations: realisations,
    companys: companys,
    labels: labels
  }
})

export default store
