<template>
  <div class="c-banner" :class="`c-banner--${context}`">
    <div class="c-banner__visual">
      <img :src="image" alt=""  class="c-banner__visual__img">
      <div class="c-banner__visual__copyright">{{ copyright }}</div>
    </div>
    <template v-if="context === $const.COMMONS.BANNER_CONTEXT_HOME">
      <div class="c-banner__content">
        <span class="c-banner__content__text--small">{{ upText }}</span>
        <h1 class="c-banner__content__title">
          <span>{{ homeTitle.first }}</span>
          <span>{{ homeTitle.second }}</span>
          <span>{{ homeTitle.third }}</span>
        </h1>
        <p class="c-banner__content__text">{{ subtitle }}</p>
      </div>
      <Scrolldown />
    </template>
    <template v-if="context === $const.COMMONS.BANNER_CONTEXT_PAGES">
      <div class="c-banner__content">
        <h1 class="c-banner__content__title">{{ title }}</h1>
        <h2 class="c-banner__content__text">{{ subtitle }}</h2>
      </div>
      <Scrolldown :title="title" />
    </template>
    <template v-if="context === $const.COMMONS.BANNER_CONTEXT_POSTS">
      <div class="background background-animated"> 
        <div class="c-banner__content">
          <h1 class="c-banner__content__title">{{ title }}</h1>
          <h2 class="c-banner__content__text">{{ subtitle }}</h2>
        </div>
      </div>
    </template>
    <template v-if="context === $const.COMMONS.BANNER_CONTEXT_POST_DETAILS">
    </template>
  </div>
</template>

<script>
import Scrolldown from './Scrolldown.vue'
import Banner from '@/assets/js/banner'

export default {
  components: { Scrolldown },
  name: 'Banner',
  props: {
    context: {
      type: String,
      default: 'context_pages',
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    homeTitle: {
      type: Object,
      default: () => ({})
    },
    upText: {
      type: String,
      default: ''
    },
    copyright: {
      type: String,
      default: ''
    }
  },
  mounted() {
		new Banner()
	},
}
</script>