<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1133 541">
    <defs>
      <linearGradient id="Dégradé_sans_nom_3" x1=".01" y1="273.77" x2="1132.99" y2="267.23" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#0009de"/>
        <stop offset="1" stop-color="#2ecdff"/>
        <stop offset="1" stop-color="#2ecdff"/>
      </linearGradient>
    </defs>
    <g>
      <path 
        stroke="url(#Dégradé_sans_nom_3)"
        fill="none"
        class="cls-1" 
        d="m1095.72,131.79c-24.53-41.02-58.3-73.15-101.31-96.45C951.34,12.09,903.08.5,849.5.5H283.44c-2.08,0-4.16,0-6.18.06-51.09.87-97.34,12.4-138.67,34.78-43.01,23.31-76.78,55.55-101.32,96.8C12.74,173.38.5,219.48.5,270.53s12.3,97.14,36.78,138.39c24.54,41.25,58.31,73.49,101.32,96.74,41.39,22.38,87.64,33.92,138.67,34.78,2.08.06,4.16.06,6.18.06h566.06c53.52,0,101.84-11.59,144.91-34.84,43.01-23.25,76.78-55.38,101.31-96.39,24.54-41.02,36.78-87.28,36.78-138.74s-12.24-97.78-36.78-138.73h0Zm-85.61,233.63c-15.99,28.27-38.05,50.24-66.05,65.99-28.06,15.75-59.58,23.65-94.56,23.65H283.44c-35.04,0-66.56-7.9-94.56-23.65-28-15.75-50.05-37.73-66.1-65.99-15.99-28.27-24.02-59.88-24.02-94.89s8.02-66.63,24.02-94.89c16.05-28.21,38.05-50.24,66.1-65.99,28-15.75,59.58-23.59,94.56-23.59h566.06c34.98,0,66.51,7.85,94.56,23.59,28,15.75,50.05,37.79,66.05,65.99,15.99,28.27,24.02,59.94,24.02,94.89s-8.03,66.63-24.02,94.89Z"/>
    </g>
  </svg>
</template>