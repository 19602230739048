<template>
  <div>
    <Banner
      :context="$const.COMMONS.BANNER_CONTEXT_POST_DETAILS"
      :image="getMedia(page.featured_media, page._embedded?.['wp:featuredmedia'])?.source_url"
    />
    <div v-if="page" class="c-intro">
      <div class="c-intro__content">
        <h1 class="c-intro__content__title" v-if="page && page.title">{{ page.title.rendered}}</h1>
        <div v-if="page.acf && page.acf.page_content" class="c-intro__content__content editor-block" v-html="page.acf.page_content">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpClient from '@/utils/httpClient';
import CONSTANTS from '@/constants';
import Banner from '@/components/Banner.vue';
import { useMedia } from '@/composables/use-media-logic'

export default {
    name: "Page",
    setup () {
      const { getMedia } = useMedia()
      return { getMedia }
    },
    data() {
        return {
            page: ""
        };
    },
    async mounted() {
        const pageSlug = this.$route.path.replace("/", "");
        const response = await httpClient.get(`${CONSTANTS.PAGES.GET_PAGES}?slug=${pageSlug}&acf_format=standard&_embed`);
        if (response && response.length) {
            this.page = response[0];
        }
    },
    components: { Banner }
}
</script>
