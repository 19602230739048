<template>
  <div v-if="pageData">
    <Banner
      v-if="pageData.acf"
      :title="pageData.acf.banner.title"
      :subtitle="pageData.acf.banner.subtitle"
      :image="getMedia(pageData.featured_media, pageData._embedded['wp:featuredmedia'])?.source_url"
    />
    <Introduction
      v-if="pageData.acf.introduction_title && pageData.acf.introduction_content"
      :title="pageData.acf.introduction_title"
      :content="pageData.acf.introduction_content"
      :cta="pageData.acf.introduction_link"
      :copyright="pageData.acf.introduction_copyright"
      :image="pageData.acf.introduction_image"
    />
    <PageSpliter title="les partenaires" />
    <CompanyPresentation
      v-if="pageData.acf.members_title && pageData.acf.members_content"
      :title="pageData.acf.members_title"
      :text="pageData.acf.members_content"
      :cta="pageData.acf.members_link"

    />
    <ContentImage
      v-if="pageData.acf && pageData.acf.block_page.title"
      :title="pageData.acf.block_page.title"
      :content="pageData.acf.block_page.content"
      :image="pageData.acf.block_page.image?.url"
      :copyright="pageData.acf.block_page.copyright"
    >
      <div class="c-methodology">
        <h3>{{ pageData.acf.methods_title }}</h3>
        <div class="c-methodology__container">
          <div v-for="(method, index) in pageData.acf.methods_items" :key="index" class="c-methodology__item">
            <p>{{method.item}}</p>
          </div>
        </div>
      </div>
    </ContentImage>
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import Introduction from '@/components/pages/Introduction'
import PageSpliter from '@/components/globals/PageSpliter'
import CompanyPresentation from '@/components/CompanyPresentation'
import ContentImage from '@/components/globals/ContentImage'
import { mapActions, mapGetters } from 'vuex'
import { useMedia } from '@/composables/use-media-logic'
import httpClient from '@/utils/httpClient'
import ScrollReveal from '@/assets/js/scrollReveal'

export default {
  name: 'Partner',
  setup () {
    const { getMedia } = useMedia()
    return { getMedia }
  },
  components: {
    Banner,
    Introduction,
    PageSpliter,
    CompanyPresentation,
    ContentImage
  },
  data () {
    return {
      pageData: null
    }
  },
  async created () {
    const response = await httpClient.get(`${this.$const.PAGES.GET_PAGES}?slug=partenariat&_embed&acf_format=standard`)
    this.pageData = response[0]
  },
  mounted () {
    this.setPageBySlug('partenariat')
  },
  computed: {
    ...mapGetters('pages', { pageContent: 'getData'})
  },
  methods: {
    ...mapActions('pages', ['setPageBySlug', 'resetPage']),
    onUpdated () {
      new ScrollReveal()
    }
  },
  beforeUnmount () {
    this.resetPage()
  }
}
</script>