<template>
  <div class="c-archive">
    <Banner
      v-if="pageData.acf"
      :context="$const.COMMONS.BANNER_CONTEXT_POSTS"
      :title="pageData.acf.banner.title"
      :subtitle="pageData.acf.banner.subtitle"
    />

    <div class="c-archive__filters">
      <Filters
        :context="$const.COMMONS.ARCHIVE_CONTEXT_NEWS"
      />
    </div>

    <div class="c-archive__list">
      <h3 v-if="filteredPosts.length <= 0">
        Aucun article ne correponds à votre recherche
      </h3>
      <template v-else>
        <TransitionGroup name="fade">
          <SinglePreview
            v-for="(item, index) in filteredPosts.slice(firstKey, secondKey)" :key="index"
            :post="item"
            :context="$const.COMMONS.ARCHIVE_CONTEXT_NEWS"
          />
        </TransitionGroup>
      </template>
    </div>

    <div class="c-archive__pagination">
      <Pagination
        v-if="filteredPosts.length > $const.COMMONS.ARCHIVE_PAGINATION_NUMBER"
        :context="$const.COMMONS.ARCHIVE_CONTEXT_NEWS"
        @updatePage="updateCurrentPage"
        />
    </div>
  </div>
</template>

<script>
import Banner from '@/components/Banner.vue';
import Filters from '@/components/Filters.vue';
import Pagination from '@/components/Pagination.vue';
import SinglePreview from '@/components/SinglePreview.vue';
import { mapGetters } from 'vuex';
import { usePaginations } from '@/composables/use-paginations-logic'
import httpClient from '@/utils/httpClient';
import CONSTANTS from '@/constants';

export default {
  name: "Actualite",
  setup () {
    const { currentPage, firstKey, secondKey, updateCurrentPage } = usePaginations()
    return {
      currentPage,
      firstKey,
      secondKey,
      updateCurrentPage
    }
  },
  data () {
    return {
      pageData: ''
    }
  },
  components: { SinglePreview, Banner, Filters, Pagination },
  computed: {
    ...mapGetters('posts', ['filteredPosts']),
  },
  async created () {
    const response = await httpClient.get(`${CONSTANTS.PAGES.GET_PAGES}?slug=actualites&acf_format=standard&_embed`)
    this.pageData = response[0]
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: all .5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>