import { useStore } from 'vuex'

export function useRealisation () {
  const store = useStore()

  const getRealisationsTag = (id) => {
    return store.getters['realisations/getRealisationsTags'].find(item => item.id === id)?.name
  }

  const getRealisationThematique = (id) => {
    return store.getters['realisations/getRealisationsThematiques'].find(item => item.id === id)
  }

  const getRealisationPartenaire = (id) => {
    return store.getters['realisations/getRealisationsPartenaires'].find(item => item.id === id)
  }

  const getRealisationAnnees = (id) => {
    return store.getters['realisations/getRealisationsAnnees'].find(item => item.id === id)
  }

  const yearChip = (realisation_annee) => {
    const first = getRealisationAnnees(realisation_annee[0])?.name
    const second = getRealisationAnnees(realisation_annee[1])?.name

    if(parseInt(first.substring(4)) < parseInt(second.substring(4))) {
      return `${first} -> ${second}`

    } else if (parseInt(first.substring(4)) == parseInt(second.substring(4))) {
        if (parseInt(first.substring(0, 2)) < parseInt(second.substring(0, 2))) {
          return `${first} -> ${second}`
        }
    } else {
      return `${second} -> ${first}`
    }
  }

  return {
    getRealisationsTag,
    getRealisationThematique,
    getRealisationPartenaire,
    getRealisationAnnees,
    yearChip
  }
}