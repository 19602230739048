<template>
  <svg :width="width" :height="height" viewBox="0 0 305 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.9012 31.9334L13.0007 23.4531C12.7532 23.4817 12.3818 23.4944 11.8867 23.4944H5.36407V31.9334H0V2.98068H11.8867C14.391 2.98068 16.5715 3.3942 18.4283 4.22124C20.2851 5.04828 21.7102 6.23476 22.7005 7.77751C23.6908 9.32343 24.1859 11.1556 24.1859 13.2773C24.1859 15.399 23.6559 17.3298 22.5958 18.9012C21.5356 20.4726 20.0153 21.6463 18.0347 22.4161L24.6811 31.9302H18.9044L18.9012 31.9334ZM16.9619 9.01807C15.7494 8.02562 13.9752 7.52939 11.6391 7.52939H5.36407V19.073H11.6359C13.9752 19.073 15.7494 18.5704 16.9587 17.562C18.168 16.5568 18.7743 15.1286 18.7743 13.2805C18.7743 11.4324 18.168 10.0137 16.9587 9.02125L16.9619 9.01807Z" fill="white"/>
    <path d="M49.1559 9.84826V31.9334H44.2457V29.0801C43.3919 30.1298 42.3413 30.9155 41.0875 31.4372C39.8338 31.962 38.4531 32.2229 36.9391 32.2229C34.7935 32.2229 32.8732 31.7553 31.1815 30.8169C29.4897 29.8785 28.1693 28.5553 27.2203 26.8471C26.2713 25.139 25.7952 23.1509 25.7952 20.8924C25.7952 18.634 26.2713 16.6523 27.2203 14.9568C28.1693 13.2614 29.4897 11.9445 31.1815 11.0061C32.8732 10.0677 34.7935 9.60015 36.9391 9.60015C38.3706 9.60015 39.6846 9.84826 40.8812 10.3445C42.0778 10.8407 43.1157 11.5723 43.9981 12.5361V9.84826H49.1559ZM42.2207 25.896C43.4585 24.6268 44.0775 22.96 44.0775 20.8924C44.0775 18.8248 43.4585 17.1548 42.2207 15.8888C40.9828 14.6196 39.4148 13.9866 37.5168 13.9866C35.6187 13.9866 34.0571 14.6228 32.8319 15.8888C31.6068 17.158 30.9942 18.8248 30.9942 20.8924C30.9942 22.96 31.6068 24.63 32.8319 25.896C34.0571 27.1652 35.6187 27.7982 37.5168 27.7982C39.4148 27.7982 40.9828 27.1652 42.2207 25.896Z" fill="white"/>
    <path d="M54.1072 5.3185C53.4724 4.72685 53.1582 3.98888 53.1582 3.10458C53.1582 2.22028 53.4756 1.48549 54.1072 0.890658C54.7389 0.299007 55.5228 0 56.4592 0C57.3955 0 58.1795 0.283102 58.8111 0.849306C59.4427 1.41551 59.7601 2.12486 59.7601 2.98052C59.7601 3.89027 59.4491 4.65687 58.8302 5.27715C58.2112 5.89743 57.4209 6.20916 56.456 6.20916C55.4911 6.20916 54.7357 5.91333 54.1041 5.3185H54.1072ZM53.8597 9.84813H59.0174V31.9333H53.8597V9.84813Z" fill="white"/>
    <path d="M65.6416 30.3425C64.3625 29.0892 63.7213 27.3174 63.7213 25.0272V1.24342H68.8791V24.655C68.8791 26.8626 69.9106 27.9632 71.9737 27.9632C72.6625 27.9632 73.2814 27.8264 73.8305 27.5496L74.0781 31.6848C73.1132 32.0443 72.0817 32.2224 70.9834 32.2224C68.6982 32.2224 66.9175 31.5958 65.6384 30.3393L65.6416 30.3425Z" fill="white"/>
    <path d="M143.5 9.8066C142.151 7.54496 140.294 5.77319 137.93 4.4881C135.562 3.20619 132.908 2.56682 129.963 2.56682H98.8418C98.7276 2.56682 98.6133 2.56682 98.5022 2.57C95.6932 2.61772 93.1508 3.2539 90.8782 4.4881C88.5136 5.77319 86.6568 7.55133 85.3079 9.82569C83.9589 12.1 83.286 14.6416 83.286 17.4567C83.286 20.2718 83.9621 22.8134 85.3079 25.0878C86.6568 27.3621 88.5136 29.1402 90.8782 30.4222C93.154 31.6564 95.6964 32.2925 98.5022 32.3403C98.6165 32.3434 98.7307 32.3434 98.8418 32.3434H129.963C132.905 32.3434 135.562 31.7041 137.93 30.4222C140.294 29.1402 142.151 27.3685 143.5 25.1068C144.849 22.8452 145.522 20.2941 145.522 17.4567C145.522 14.6193 144.849 12.0651 143.5 9.8066ZM138.793 22.6893C137.914 24.248 136.701 25.4599 135.162 26.3283C133.619 27.1967 131.886 27.6325 129.963 27.6325H98.8418C96.9152 27.6325 95.1822 27.1967 93.6428 26.3283C92.1034 25.4599 90.8909 24.248 90.0086 22.6893C89.1294 21.1307 88.6882 19.3875 88.6882 17.4567C88.6882 15.5259 89.1294 13.7828 90.0086 12.2241C90.8909 10.6686 92.1002 9.45352 93.6428 8.58513C95.1822 7.71673 96.9184 7.28413 98.8418 7.28413H129.963C131.886 7.28413 133.619 7.71673 135.162 8.58513C136.701 9.45352 137.914 10.6686 138.793 12.2241C139.672 13.7828 140.113 15.5291 140.113 17.4567C140.113 19.3844 139.672 21.1307 138.793 22.6893Z" fill="white"/>
    <path d="M165.524 11.0473C167.216 11.9856 168.543 13.3089 169.508 15.0171C170.47 16.7284 170.952 18.6974 170.952 20.9304C170.952 23.1634 170.47 25.1419 169.508 26.866C168.543 28.59 167.216 29.9197 165.524 30.858C163.833 31.7964 161.912 32.264 159.767 32.264C156.796 32.264 154.444 31.2716 152.708 29.2867V40H147.55V9.88941H152.46V12.784C153.314 11.7375 154.365 10.9518 155.618 10.427C156.869 9.90213 158.253 9.6413 159.767 9.6413C161.912 9.6413 163.833 10.1089 165.524 11.0473ZM163.874 25.9372C165.099 24.668 165.712 23.0012 165.712 20.9336C165.712 18.866 165.099 17.196 163.874 15.93C162.649 14.6608 161.087 14.0278 159.189 14.0278C157.951 14.0278 156.837 14.3109 155.847 14.8771C154.857 15.4433 154.073 16.2481 153.495 17.2978C152.917 18.3475 152.628 19.5594 152.628 20.9367C152.628 22.3141 152.917 23.5292 153.495 24.5757C154.073 25.6254 154.857 26.4302 155.847 26.9964C156.837 27.5626 157.951 27.8457 159.189 27.8457C161.087 27.8457 162.649 27.2127 163.874 25.9435V25.9372Z" fill="white"/>
    <path d="M190.266 25.4822L192.989 28.6663C192.024 29.8528 190.786 30.7498 189.276 31.3542C187.762 31.9617 186.057 32.2639 184.159 32.2639C181.737 32.2639 179.604 31.7804 177.76 30.8166C175.916 29.8528 174.494 28.5072 173.488 26.7832C172.485 25.0591 171.98 23.1092 171.98 20.9303C171.98 18.7514 172.469 16.8428 173.447 15.1187C174.424 13.3947 175.77 12.0523 177.49 11.0853C179.211 10.1215 181.156 9.63801 183.331 9.63801C185.505 9.63801 187.273 10.0865 188.964 10.9835C190.656 11.8806 191.999 13.1625 192.989 14.8293C193.979 16.4993 194.475 18.4492 194.475 20.6822L177.595 23.9903C178.119 25.2595 178.957 26.2233 180.112 26.885C181.267 27.5466 182.658 27.8774 184.28 27.8774C186.673 27.8774 188.669 27.079 190.263 25.479L190.266 25.4822ZM178.731 15.6372C177.56 16.8778 176.976 18.5478 176.976 20.6408V20.6822L189.441 18.2838C189.082 16.9319 188.361 15.844 187.273 15.017C186.184 14.1899 184.873 13.7764 183.331 13.7764C181.433 13.7764 179.896 14.3967 178.728 15.6372H178.731Z" fill="white"/>
    <path d="M216.556 12.0811C218.235 13.7097 219.073 16.1208 219.073 19.3208V31.9777H213.915V19.9825C213.915 18.0517 213.461 16.598 212.554 15.6182C211.646 14.6385 210.351 14.1487 208.675 14.1487C206.777 14.1487 205.276 14.7212 204.178 15.8664C203.076 17.0115 202.527 18.6592 202.527 20.8095V31.9777H197.369V9.88941H202.279V12.7427C203.133 11.7216 204.206 10.9518 205.498 10.427C206.79 9.90213 208.25 9.6413 209.872 9.6413C212.649 9.6413 214.88 10.4556 216.559 12.0811H216.556Z" fill="white"/>
    <path d="M233.394 3.02221H238.758V27.4262H253.863V31.975H233.391V3.02221H233.394Z" fill="white"/>
    <path d="M276.894 9.88916V31.9743H271.984V29.121C271.13 30.1707 270.08 30.9564 268.826 31.4781C267.572 32.0029 266.191 32.2638 264.677 32.2638C262.532 32.2638 260.611 31.7962 258.92 30.8578C257.228 29.9194 255.908 28.5962 254.959 26.888C254.01 25.1799 253.533 23.1918 253.533 20.9333C253.533 18.6749 254.01 16.6932 254.959 14.9977C255.908 13.3023 257.228 11.9854 258.92 11.047C260.611 10.1086 262.532 9.64105 264.677 9.64105C266.109 9.64105 267.423 9.88916 268.619 10.3854C269.816 10.8816 270.854 11.6132 271.736 12.577V9.88916H276.894ZM269.959 25.9369C271.197 24.6677 271.816 23.0009 271.816 20.9333C271.816 18.8657 271.197 17.1957 269.959 15.9297C268.721 14.6605 267.153 14.0275 265.255 14.0275C263.357 14.0275 261.795 14.6637 260.57 15.9297C259.345 17.1989 258.732 18.8657 258.732 20.9333C258.732 23.0009 259.345 24.6709 260.57 25.9369C261.795 27.2061 263.357 27.8391 265.255 27.8391C267.153 27.8391 268.721 27.2061 269.959 25.9369Z" fill="white"/>
    <path d="M299.572 11.0474C301.264 11.9858 302.591 13.309 303.556 15.0172C304.518 16.7285 305 18.6975 305 20.9305C305 23.1635 304.518 25.1421 303.556 26.8661C302.591 28.5902 301.264 29.9198 299.572 30.8582C297.881 31.7966 295.96 32.2642 293.815 32.2642C292.301 32.2642 290.917 32.0033 289.666 31.4785C288.413 30.9536 287.362 30.1679 286.508 29.1214V31.9747H281.598V1.28516H286.756V12.5774C287.635 11.6136 288.676 10.882 289.873 10.3858C291.069 9.88955 292.383 9.64144 293.815 9.64144C295.96 9.64144 297.881 10.109 299.572 11.0474ZM297.922 25.9373C299.147 24.6681 299.76 23.0013 299.76 20.9337C299.76 18.8661 299.147 17.1961 297.922 15.9301C296.697 14.6609 295.135 14.0279 293.237 14.0279C291.999 14.0279 290.885 14.311 289.895 14.8772C288.905 15.4434 288.121 16.2482 287.543 17.2979C286.965 18.3476 286.676 19.5596 286.676 20.9369C286.676 22.3142 286.965 23.5293 287.543 24.5759C288.121 25.6256 288.905 26.4303 289.895 26.9965C290.885 27.5628 291.999 27.8459 293.237 27.8459C295.135 27.8459 296.697 27.2129 297.922 25.9437V25.9373Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 40
    },
    width: {
      type: Number,
      default: 305
    }
  }
}
</script>