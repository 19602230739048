<template>
  <div class="c-testimonials-carousel">
    <h2 class="c-testimonials-carousel__title">{{ title }}</h2>
    <div class="c-testimonials-carousel__wrapper">
      <div
        v-for="(slide, index) in slides"
        :key="index"
        class="c-testimonials-carousel__item"
        v-show="currentItem === index"
      >
        <div class="c-testimonials-carousel__item__visual">
          <img :src="getMedia(slide.featured_media, slide._embedded['wp:featuredmedia'])?.source_url" :alt="slide.acf.fullname">
        </div>
        <div class="c-testimonials-carousel__item__content">
          <p class="testimony">{{ slide.acf.testimony_content }}</p>
          <p class="fullname">{{ slide.acf.testimony_fullname }}</p>
          <p class="job">{{ slide.acf.testimony_job }}</p>
        </div>
      </div>
      <div v-if="slides && slides.length > 1" class="c-testimonials-carousel__navigation">
        <div class="c-testimonials-carousel__navigation__prev" @click="prevItem"><span class="icon-chevron-left"></span></div>
        <div class="c-testimonials-carousel__navigation__next" @click="nextItem"><span class="icon-chevron-right"></span></div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMedia } from '@/composables/use-media-logic'

export default {
  name: 'TestimonialsCarousel',
  setup () {
    const { getMedia } = useMedia()
    return { getMedia }
  },
  props: {
    slides: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: 'Ils témoignent'
    }
  },
  data () {
    return {
      currentItem: 0
    }
  },
  methods: {
    nextItem () {
      if (this.currentItem === this.slides.length - 1) {
        this.currentItem = 0
      } else {
        this.currentItem++
      }
    },
    prevItem () {
      if (this.currentItem === 0) {
        this.currentItem = this.slides.length - 1
      } else {
        this.currentItem--
      }
    }
  }
}
</script>