<template>
  <div
    class="loader background background-animated"
    :class="{ 'loader--visible': visible }"
  >
    <router-link to="/" class="loader__logo">
        <LogoRailOpenLab />
    </router-link>
    <div class="loader__nb">
      Loading
    </div>
  </div>
</template>
<script>
import LogoRailOpenLab from '@/components/vector/LogoRailOpenLab.vue';

export default {
    name: "loader",
    props: ["visible"],
    components: { LogoRailOpenLab }
};
</script>
