import POSTS from "@/constants/posts";
import httpClient from "@/utils/httpClient";

const posts = {
  namespaced: true,
  state: {
    posts: [],
    posts_annees: [],
    posts_categories: [],
    posts_tags: [],
    posts_filters: {
      annee: '',
      categories: ''
    },
  },
  getters: {
    getAllPosts(state) {
      return state.posts
    },
    getCompanys (state) {
      return state.companys
    },
    filteredRealisations (state, getter) {
      return getter.filterByThematiques(getter.filterByPartenaire(getter.filterRealisationsByAnnee(state.realisations)))
    },
    filterByThematiques: (state) => (realisations) => {
      return state.realisations_filters.thematique ? realisations.filter(real => real['thematiques-real'].includes(parseInt(state.realisations_filters.thematique))) :realisations
    },
    filterRealisationsByAnnee: (state) => (realisations) => {
      return state.realisations_filters.annee ? realisations.filter(real => real['annees-real'].includes(parseInt(state.realisations_filters.annee))) : realisations
    },
    filterByPartenaire: (state) => (realisations) => {
      return state.realisations_filters.partenaire ? realisations.filter(real => real['partenaires-real'].includes(parseInt(state.realisations_filters.partenaire))) : realisations
    },
    filteredPosts (state, getter) {
      return getter.filterByCategories(getter.filterPostsByAnnee(state.posts))
    },
    filterByCategories: (state) => (posts) => {
      return state.posts_filters.categories ? posts.filter(post => post.categories.includes(parseInt(state.posts_filters.categories))) : posts
    },
    filterPostsByAnnee: (state) => (posts) => {
      return state.posts_filters.annee ? posts.filter(post => post['annees-post'].includes(parseInt(state.posts_filters.annee))) : posts
    },
    getPostsAnnees (state) {
      return state.posts_annees
    },
    getPostsTags (state) {
      return state.posts_tags
    },
    getPostsCategories (state) {
      return state.posts_categories
    }
  },
  mutations: {
    SET_ALL_POSTS (state, datas) {
      state.posts = datas
    },
    SET_POSTS_CATEGORIES (state, datas) {
      state.posts_categories = datas
    },
    SET_POSTS_ANNNEES (state, datas) {
      state.posts_annees = datas
    },
    SET_POSTS_FILTERS (state, datas) {
      state.posts_filters[datas.field] = datas.value
    },
    SET_POSTS_TAGS (state, datas) {
      state.posts_tags = datas
    },
  },
  actions: {
    async setAllPosts({commit}) {
      const response = await httpClient.get(POSTS.GET_POSTS)
      commit('SET_ALL_POSTS', response)
    },
    async setPostsCategories ({commit}) {
      const response = await httpClient.get(POSTS.GET_POSTS_CATEGORIES)
      commit('SET_POSTS_CATEGORIES', response)
    },
    async setPostsAnnees ({commit}) {
      const response = await httpClient.get(POSTS.GET_POSTS_ANNEES)
      commit('SET_POSTS_ANNNEES', response)
    },
    setRealisationFilters ({commit}, filter) {
      commit('SET_REALISATION_FILTERS', filter)
    },
    setPostsFilters ({commit}, filter) {
      commit('SET_POSTS_FILTERS', filter)
    },
    async setAllPostsTags ({commit}) {
      const response = await httpClient.get(POSTS.GET_POSTS_TAGS)
      commit('SET_POSTS_TAGS', response)
    },
  }
}
export default posts
