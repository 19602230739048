export function useTestimonies () {
  const getTestimonies = (arrId, arr) => {
    if (!arrId && !arrId.length < 1) return false
    let newArr = []
    arrId.forEach((id) => {
      newArr.push(arr.find(arr => arr.id === id))
    })

    return newArr
  }

  return {
    getTestimonies
  }
}