<template>
	<div class="c-links-cloud">
		<div class="c-links-cloud__container">
			<span v-for="link, index in links" :key="index" class="c-links-cloud__item h2-like" @mousemove="followCursor">
				{{ link.label }}
				<span class="c-links-cloud__item__visual" data-links-cloud-item-visual>
					<img :src="link.image.url" alt=""  class="c-links-cloud__item__visual__img">
				</span>
			</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'LinksCloud',
	props: {
		links: {
			type: Array,
			required: true
		}
	},
	methods: {
		followCursor(e) {
			let elem = e.target
			let img = e.target?.querySelector('[data-links-cloud-item-visual]')
			let x = e.offsetX;
			let y = e.offsetY;

			e.target.classList.add('c-links-cloud__item--active')

			if (img) {
				img.style.left = `${x - 50}px`;
				img.style.top = `${y + 30}px`;
			}

			elem.addEventListener('mouseleave', () => {
				elem.classList.remove('c-links-cloud__item--active')
			})
		},
	}
}
</script>