import { createWebHashHistory, createWebHistory, createRouter } from "vue-router";
import Home from '@/views/Home.vue';
import Page from '@/views/Page.vue';
import NotFound from '@/views/NotFound.vue';
import Single from "@/views/Single.vue";
import Contact from '@/views/Contact.vue';
import About from '@/views/About.vue';
import SingleRealisation from '@/views/SingleRealisation.vue';
import Realisations from '@/views/Realisations.vue';
import Partner from '@/views/Partner.vue';
import Actualite from '@/views/Actualite.vue';
import Admin from '@/views/Admin.vue';
import BecamePartner from '@/views/BecamePartner.vue'

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/page",
    name: "Page",
    component: Page
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    path: '/devenir-partenaire',
    name: "BecamePartner",
    component: BecamePartner
  },
  {
    path: "/realisations",
    name: "Realisations",
    component: Realisations
  },
  {
    path: "/realisations/:slug",
    name: "Realisation",
    component: SingleRealisation
  },
  {
    path: "/actualites",
    name: "Actualites",
    component: Actualite
  },
  {
    path: "/presentation",
    name: "Rail Open Lab",
    component: About
  },
  {
    path: "/actualites/:slug",
    name: "Single",
    component: Single
  },
  {
    path: "/partenariat",
    name: "Partenariat",
    component: Partner
  },
  {
    path: "/mentions-legales",
    name: "Mentions légales",
    component: Page
  },
  {
    path: "/politique-de-confidentialites",
    name: "Politique de confidentialités",
    component: Page
  },
  {
    path: "/credits",
    name: "Crédits",
    component: Page
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound
  },
  { 
    path: '/:pathMatch(.*)',
    component: NotFound 
  }
]

const router = createRouter({
  history: process.env.NODE_ENV === 'development' ? createWebHashHistory() : createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return {
      top: 0,
    }
  },
})

export default router;