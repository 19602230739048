<template>
	<div v-if="post" class="c-intro">
    <div class="c-intro__chips" v-if="context === $const.COMMONS.ARCHIVE_CONTEXT_REALISATIONS">
      <Chip
        :label="yearChip(post['annees-real'])"
        dark
      />
      <Chip
        v-for="(theme, index) in post['thematiques-real']"
        :key="index"
        dark
        :label="getRealisationThematique(theme)?.name"
      />
      <Chip
        v-for="(partenaire, index) in post['partenaires-real']"
        :key="index"
        dark
        :label="getRealisationPartenaire(partenaire)?.name"
      />
    </div>
    <div class="c-intro__chips" v-if="context === $const.COMMONS.ARCHIVE_CONTEXT_NEWS">
      <Chip
        v-for="(theme, index) in post['annees-post']"
        :key="index"
        dark
        :label="getPostAnnees(theme)"
      />
      <Chip
        v-for="(partenaire, index) in post.categories"
        :key="index"
        dark
        :label="getPostCategory(partenaire)"
      />
    </div>
    <div class="c-intro__content">
      <h1 class="c-intro__content__title" v-html="post.title?.rendered || post.title"></h1>
      <div class="c-intro__content__tags" v-if="context === $const.COMMONS.ARCHIVE_CONTEXT_REALISATIONS">
        <Tag
          v-for="(tagId, index) in post['tags-real']"
          :key="index"
          :label="getRealisationsTag(tagId)"
        />
      </div>
      <div class="c-intro__content__tags" v-if="context === $const.COMMONS.ARCHIVE_CONTEXT_NEWS">
        <Tag
          v-for="(tagId, index) in post.tags"
          :key="index"
          :label="getPostTag(tagId)"
        />
      </div>
      <h2 
        v-if="post.acf && post.acf.real_intro_subtitle"
        class="c-intro__content__subtitle h3-like"
      >{{ post.acf.real_intro_subtitle }}</h2>

      <div 
        v-if="post.acf && post.acf.real_intro_video?.real_intro_video_link"
        class="c-intro__content__video"
      >
        <Video
          :img="post.acf.real_intro_video?.real_intro_video_img"
          :url="post.acf.real_intro_video?.real_intro_video_link"
        />
      </div>
      <div v-if="post.acf && post.acf.real_intro_content " class="c-intro__content__content editor-block" v-html="post.acf.real_intro_content">
      </div>
      <div v-if="post.acf && post.acf.post_intro_content " class="c-intro__content__content editor-block" v-html="post.acf.post_intro_content">
      </div>
    </div>
	</div>
</template>

<script>
import Chip from './globals/Chip.vue';
import Tag from './globals/Tag.vue';
import Video from './Video.vue';
import { useRealisation } from '@/composables/use-realisations-logic'
import { usePosts } from '@/composables/use-posts-logic'

export default {
  setup () {
    const { getRealisationsTag, getRealisationThematique, getRealisationPartenaire, getRealisationAnnees, yearChip } = useRealisation()
    const { getPostAnnees, getPostCategory, getPostTag } = usePosts()
    
    return {
      getRealisationsTag,
      getRealisationThematique,
      getRealisationPartenaire,
      getRealisationAnnees,
      getPostAnnees,
      getPostCategory,
      getPostTag,
      yearChip
    }
  },
  name: "Intro",
  props: {
    post: {
      type: Object,
      default: () => ({})
    },
    context: {
      type: String,
      default: ''
    }
  },
  components: { Tag, Chip, Video }
}
</script>