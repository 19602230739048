<template>
  <div class="members">
    <div class="members__marquee static">
      <div class="members__marquee__group">
        <div
          v-for="(company, index) in majorCompanys"
          class="members__marquee__group__item"
          :key="index"
        >
          <img :src="getMedia(company.featured_media, company._embedded?.['wp:featuredmedia'])?.source_url" :alt="company.slug">
        </div>
      </div>
    </div>
    <div class="members__marquee">
      <div class="members__marquee__group">
         <div
            v-for="(company, index) in secondaryCompanys.sort(() => 0.5 - Math.random())"
            class="members__marquee__group__item"
            :key="index"
          >
          <img :src="getMedia(company.featured_media, company._embedded?.['wp:featuredmedia'])?.source_url" :alt="company.slug">
        </div>
      </div>
      <div aria-hidden="true" class="members__marquee__group">
         <div
            v-for="(company, index) in secondaryCompanys.sort(() => 0.5 - Math.random())"
            class="members__marquee__group__item"
            :key="index"
          >
          <img :src="getMedia(company.featured_media, company._embedded?.['wp:featuredmedia'])?.source_url" :alt="company.slug">
        </div>
      </div>
    </div>
    <div class="members__marquee reverse">
       <div class="members__marquee__group">
         <div
            v-for="(company, index) in secondaryCompanys.sort(() => 0.5 - Math.random())"
            class="members__marquee__group__item"
            :key="index"
          >
          <img :src="getMedia(company.featured_media, company._embedded?.['wp:featuredmedia'])?.source_url" :alt="company.slug">
        </div>
      </div>
      <div aria-hidden="true" class="members__marquee__group">
         <div
            v-for="(company, index) in secondaryCompanys.sort(() => 0.5 - Math.random())"
            class="members__marquee__group__item"
            :key="index"
          >
          <img :src="getMedia(company.featured_media, company._embedded?.['wp:featuredmedia'])?.source_url" :alt="company.slug">
        </div>
      </div>
    </div>
    <div class="members__marquee">
       <div class="members__marquee__group">
         <div
            v-for="(company, index) in secondaryCompanys.sort(() => 0.5 - Math.random())"
            class="members__marquee__group__item"
            :key="index"
          >
          <img :src="getMedia(company.featured_media, company._embedded?.['wp:featuredmedia'])?.source_url" :alt="company.slug">
        </div>
      </div>
      <div aria-hidden="true" class="members__marquee__group">
         <div
            v-for="(company, index) in secondaryCompanys.sort(() => 0.5 - Math.random())"
            class="members__marquee__group__item"
            :key="index"
          >
          <img :src="getMedia(company.featured_media, company._embedded?.['wp:featuredmedia'])?.source_url" :alt="company.slug">
        </div>
      </div>
    </div>
    <div class="members__content">
      <h2>{{ title }}</h2>
      <p>{{ content }}</p>
      <Cta
        v-if="cta.title"
        :label="cta.title"
        :link="cta.link.url"
        :target="cta.link.target"  
        type="link"
        />
    </div>
  </div>
</template>

<script>
import Cta from '@/components/globals/Cta.vue'
import { mapGetters } from 'vuex'
import { useMedia } from '@/composables/use-media-logic'

export default {
  name: 'Members',
  setup () {
    const { getMedia } = useMedia()
    return { getMedia}
  },
  components: {
    Cta
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    cta: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters('companys', [
      'majorCompanys',
      'secondaryCompanys'
    ])
  }
}
</script>