<template>
  <div class="c-filters">
    <span class="c-filters__label">
      Trier par: 
    </span>

    <div class="c-filters__container">
      <template v-if="context === $const.COMMONS.ARCHIVE_CONTEXT_REALISATIONS">
        <Select
          label="Thématiques"
          :value="realisations_thematiques"
          @setItem="setThematique"
        />
        <Select
          label="Partenaire"
          :value="realisations_partenaires"
          @setItem="setPartenaire"
        />
        <Select
          label="Année"
          :value="realisations_annees"
          @setItem="setRealisationAnnee"
          type="subYear"
        />
      </template>
      <template v-if="context === $const.COMMONS.ARCHIVE_CONTEXT_NEWS">
        <Select
          label="Categories"
          :value="posts_categories"
          @setItem="setCategories"
        />
        <Select
          label="Année"
          :value="posts_annees"
          @setItem="setPostAnnee"
        />
      </template>
    </div>
	</div>
</template>

<script>
import Select from './globals/Select.vue';
import { mapActions, mapState } from 'vuex';

export default {
  name: "Filters",
  components: { Select },
  props: {
    context: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      thematiqueSelected: '',
      partenaireSelected: '',
      anneeSelected: ''
    }
  },
  computed: {
    ...mapState('posts', [
      'posts_annees',
      'posts_categories'
    ]),
    ...mapState('realisations', [
      'realisations_tags',
      'realisations_thematiques',
      'realisations_annees',
      'realisations_partenaires',
    ])
  },
  methods: {
    ...mapActions('posts', ['setPostsFilters']),
    ...mapActions('realisations', ['setRealisationFilters']),
    setThematique (value) {
      this.setRealisationFilters({field: 'thematique', value: value})
    },
    setPartenaire (value) {
      this.setRealisationFilters({field: 'partenaire', value: value})
    },
    setRealisationAnnee (value) {
      this.setRealisationFilters({field: 'annee', value: value})
    },
    setPostAnnee (value) {
      this.setPostsFilters({field: 'annee', value: value})
    },
    setCategories (value) {
      this.setPostsFilters({field: 'categories', value: value})
    }
  }
}
</script>