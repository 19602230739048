class Banner {
  constructor() {
    this.bannerPage()
  }
  bannerPage () {
    let bannerHead = document.querySelector('.c-banner')

    if (bannerHead) {
      let bannerVisual = bannerHead?.querySelector('.c-banner__visual')

      if (bannerVisual && window.innerWidth > 992) {
        window.addEventListener('scroll', () => {
          if (bannerHead.classList.contains('c-banner--context_post_details')) {
            bannerVisual.style.top = `${window.scrollY - (window.scrollY/1.2)}px`
            bannerVisual.style.transform = `scale(${1 + (window.scrollY/6000)}`
          } else {
            bannerVisual.style.top = `${window.scrollY - (window.scrollY/1.5)}px`
            bannerVisual.style.transform = `scale(${1 + (window.scrollY * 0.0002)}`
          }
        })
      }
    }
  }
}

export default Banner