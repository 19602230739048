const POSTS = {
	GET_POSTS: `${process.env.VUE_APP_ENDPOINT}/posts?per_page=99&_embed`,
	GET_ALL_REALISATIONS: `${process.env.VUE_APP_ENDPOINT}/realisations?_embed&per_page=99&acf_format=standard`,
	GET_THUMBNAIL: `${process.env.VUE_APP_ENDPOINT}/media`,
	GET_COMPANYS: `${process.env.VUE_APP_ENDPOINT}/societes?_embed&per_page=99&order=asc`,
	GET_LABELS: `${process.env.VUE_APP_ENDPOINT}/labels?_embed&per_page=99&order=asc`,
	GET_REALISATIONS_TAGS: `${process.env.VUE_APP_ENDPOINT}/tags-real?per_page=99`,
	GET_REALISATIONS_THEMATIQUES: `${process.env.VUE_APP_ENDPOINT}/thematiques-real?per_page=99`,
	GET_REALISATIONS_ANNEES: `${process.env.VUE_APP_ENDPOINT}/annees-real?per_page=99`,
	GET_REALISATIONS_PARTENAIRES: `${process.env.VUE_APP_ENDPOINT}/partenaires-real?per_page=99`,
	GET_POSTS_CATEGORIES: `${process.env.VUE_APP_ENDPOINT}/categories?per_page=99`,
	GET_POSTS_ANNEES: `${process.env.VUE_APP_ENDPOINT}/annees-post?per_page=99`,
	GET_POSTS_TAGS: `${process.env.VUE_APP_ENDPOINT}/tags?per_page=99`,
	GET_LABELS_CATEGORIES: `${process.env.VUE_APP_ENDPOINT}/labels_categories?per_page=99`,
	GET_TESTIMONIES: `${process.env.VUE_APP_ENDPOINT}/testimony?per_page=99&_embed`
}

export default POSTS
