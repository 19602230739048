<template>
  <div v-if="tabs && tabs.length" class="c-tabs" data-tabs>
    <h2 class="c-tabs__title h1-like">Les thématiques</h2>
    <div class="c-tabs__container">
      <div class="c-tabs__head">
        <div v-for="tab,index in tabs" :key="index" class="c-tabs__head__item" data-tabs-head-item @click="(e) => tabsNav(e, index)">{{ tab.title }}</div>
      </div>
      <div class="c-tabs__content" data-tabs-content>
        <div v-for="tab,index in tabs" :key="index" class="c-tabs__content__item" data-tabs-content-item>
          <div v-if="tab.image && tab.image?.url" class="c-tabs__content__item__visual">
            <img :src="tab.image.url" alt="">
          </div>
          <div class="c-tabs__content__item__content editor-block" v-html="tab.contenu">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Tabs",
    props: {
      tabs: {
        type: Array,
        required: true
      }
    },
    mounted() {
      this.loadTabs()
    },
    methods: {
      tabsNav(e, i) {
        let current = e.target
        let siblings = e.target.parentNode.children
        let contents = [].slice.call(this.$el.querySelectorAll('[data-tabs-content-item]'))

        for (let j = 0; j < siblings.length; j++ ) {
          if (siblings[j] !== current) {
            siblings[j].classList.remove('c-tabs__head__item--active')
            contents[j].classList.remove('c-tabs__content__item--active')
          } else {
            current.classList.add('c-tabs__head__item--active')
            contents[i].classList.add('c-tabs__content__item--active')
          }
        }
      },
      loadTabs() {
        let headItems = this.$el.querySelectorAll('[data-tabs-head-item]')
        let contentItems = this.$el.querySelectorAll('[data-tabs-content-item]')

        headItems[0].classList.add('c-tabs__head__item--active')
        contentItems[0].classList.add('c-tabs__content__item--active')
      }
    }
  }
</script>