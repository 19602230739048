<template>
  <div v-if="pageData">
    <Banner
      :context="$const.COMMONS.BANNER_CONTEXT_HOME"
      :homeTitle="homeTitle"
      :subtitle="pageData.acf.banner_subtitle"
      :upText="pageData.acf.banner_up_text"
      :image="getMedia(pageData.featured_media, pageData._embedded['wp:featuredmedia'])?.source_url"
    />
    <Introduction
      v-if="pageData.acf.introduction_title && pageData.acf.introduction_content"
      :title="pageData.acf.introduction_title"
      :copyright="pageData.acf.introduction_copyright"
      :content="pageData.acf.introduction_content"
      :cta="pageData.acf.introduction_link"
      :image="pageData.acf.introduction_image"
    />
    <Members
      v-if="pageData.acf.members_title && pageData.acf.members_content && pageData.acf.members_bouton"
      :title="pageData.acf.members_title"
      :content="pageData.acf.members_content"
      :cta="pageData.acf.members_bouton"
    />
    <template v-if="selectedRealisations.length">
      <PageSpliter title="Nos dernieres réalisations" />
      <SinglePreviewCarousel
        :context="$const.COMMONS.ARCHIVE_CONTEXT_REALISATIONS"
        :posts="selectedRealisations"
        cta-label="Voir toutes les réalisations"
        cta-link="/realisations"
      />
    </template>
    <TestimonialsCarousel
      v-if="testimonies"
      :slides="testimonies"
    />
    <!-- <template v-if="posts.length">
      <PageSpliter title="Nos derniers articles" />
      <SinglePreviewCarousel
        :context="$const.COMMONS.ARCHIVE_CONTEXT_NEWS"
        :posts="posts.slice(0, 10)"
        cta-label="Voir toutes les actualités"
        cta-link="/actualites"
      />
    </template>-->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Banner from '@/components/Banner'
import Introduction from '@/components/pages/Introduction'
import Members from '@/components/pages/Members'
import PageSpliter from '@/components/globals/PageSpliter'
import SinglePreviewCarousel from '@/components/globals/carousel/SinglePreviewCarousel'
import TestimonialsCarousel from '@/components/globals/carousel/TestimonialsCarousel'
import httpClient from '@/utils/httpClient'
import { useMedia } from '@/composables/use-media-logic'
import ScrollReveal from '@/assets/js/scrollReveal'

export default {
  name: 'Home',
  setup () {
    const { getMedia } = useMedia()
    return { getMedia } 
  },
  components: {
    Banner,
    Introduction,
    Members,
    PageSpliter,
    SinglePreviewCarousel,
    TestimonialsCarousel
  },
  data () {
    return {
      pageData: null,
      testimonies: null
    }
  },
  async created () {
    const response = await httpClient.get(`${this.$const.PAGES.GET_HOME}`)
    this.pageData = response[0]
  },
  async mounted () {
    this.testimonies = await httpClient.get(`${this.$const.POSTS.GET_TESTIMONIES}`)
  },
  computed: {
    posts() {
      return this.$store.getters['posts/getAllPosts']
    },
    ...mapState('realisations', [
      'realisations'
    ]),
    homeTitle () {
      return {
        first: this.pageData.acf.banner_title.first,
        second: this.pageData.acf.banner_title.second,
        third: this.pageData.acf.banner_title.third,
      }
    },
    selectedRealisations () {
      if (this.pageData.acf.realisations.length) {
        return this.realisations.filter((realisation) => this.pageData.acf.realisations.some(real => real === realisation.id))
      }
      return []
    }
  },
  methods: {
    onUpdated () {
      new ScrollReveal()
    }
  }
}
</script>