const MENUS = {
  GET_NAVBAR: `${process.env.VUE_APP_ENDPOINT}/menu`,
  GET_FOOTER: `${process.env.VUE_APP_ENDPOINT}/footer`
}

const CTA = {
  TYPE: {
      ACTION: 'action',
      LINK: 'link',
      SUBMIT: 'submit'
  },
  LABEL: 'Devenir partenaire'
}

const FORM_BASE_URL = `${process.env.VUE_APP_FORM_ENDPOINT}`
const FROM_FULLNAME_REQUIRED = 'Certains champs obligatoire ne sont pas rempli. Merci de remplir les champs necessaire' 

const BANNER_CONTEXT_HOME = 'context_home'
const BANNER_CONTEXT_PAGES = 'context_pages'
const BANNER_CONTEXT_POSTS = 'context_posts'
const BANNER_CONTEXT_POST_DETAILS = 'context_post_details'
const ARCHIVE_CONTEXT_NEWS = 'context_actualite'
const ARCHIVE_CONTEXT_REALISATIONS = 'context_realisation'
const ARCHIVE_PAGINATION_NUMBER = 10

const COMPANY_ARRAY = ["sncf-reseau", "ratp", "serce", "fif"]

export default {
  MENUS,
  CTA,
  BANNER_CONTEXT_HOME,
  BANNER_CONTEXT_PAGES,
  BANNER_CONTEXT_POSTS,
  BANNER_CONTEXT_POST_DETAILS,
  ARCHIVE_CONTEXT_NEWS,
  ARCHIVE_CONTEXT_REALISATIONS,
  ARCHIVE_PAGINATION_NUMBER,
  FORM_BASE_URL,
  FROM_FULLNAME_REQUIRED,
  COMPANY_ARRAY
}