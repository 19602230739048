<template>
  <div class="introduction">
    <div class="introduction__card">
      <div class="introduction__card__visual">
        <img :src="image?.url" :alt="title" />
        <p class="copyright">{{ copyright }}</p>
      </div>
      <div class="introduction__card__content">
        <h2 v-if="title"><span>{{ title }}</span></h2>
        <div v-if="content" class="introduction__card__content__text editor-block" v-html="content"></div>
        <Cta
          v-if="cta.title"
          :label="cta.title"
          :link="cta.url ?? cta.link.url"
          :target="cta.target ?? cta.link.target"  
          type="link"
        />
        <div class="introduction__card__slot">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cta from '@/components/globals/Cta.vue'
export default {
  name: 'Introduction',
  components: {
    Cta
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    cta: {
      type: Object,
      default: () => ({})
    },
    image: {
      type: Object,
      default: () => ({})
    },
    copyright: {
      type: String,
      default: ''
    }
  }
}
</script>