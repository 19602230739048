<template>
  <div class="c-company-presentation">
    <div class="c-company-presentation__header">
      <h2>{{ title }}</h2>
      <div v-if="text" v-html="text"></div>
    </div>
    <div class="c-company-presentation__companys" v-if="companys">
      <div
        v-for="(company, index) in companys"
        :key="index"
        class="c-company-presentation__companys__item"
        @click="setCurrentCompany(company)"
      >
        <img :src="imgURL(company)" :alt="company.title.rendered" />
      </div>
    </div>
      <CompanyPresentationOverlay
        v-if="currentCompany"
        :company="currentCompany"
        @resetCurrentCompany="resetCurrentCompany"
      />
    <Cta
      v-if="cta.title && cta.url"
      :label="cta.title"
      :link="cta.url"
      :target="cta.target"  
      type="link"
    />
  </div>
</template>

<script>
import Cta from '@/components/globals/Cta'
import CompanyPresentationOverlay from '@/components/CompanyPresentationOverlay'
import { mapState } from 'vuex'

export default {
  name: 'CompanyPresentation',
  components: {
    Cta,
    CompanyPresentationOverlay
  },
  props: {
    title: {
      type: String,
      default: 'Découvrez les partenaires formant ROL'
    },
    text: {
      type: String,
      default: 'Usine à accélérer et délivrer des projets digitaux destinés à transformer les 	pratiques de Maintenance et d’Exploitation de la filière ferroviaire, le Rail Open Lab permet 	aux acteurs de cette filière de tester et/ou d’améliorer des produits existants ou non, en 	conditions réelles, à des fins de commercialisation sur un marché plus large et global.'
    },
    cta: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      currentCompany: null,
      showModal: false
    }
  },
  computed: {
    ...mapState('companys', ['companys'])
  },
  methods: {
    imgURL (item) {
      const media = item._embedded?.['wp:featuredmedia']
      if (media) return media[0].source_url
      return ''
    },
    setCurrentCompany (company) {
      this.currentCompany = company
      document.querySelector('body').classList.add('no-scroll')
      document.querySelector('html').classList.add('no-scroll')
    },
    resetCurrentCompany () {
      document.querySelector('.c-company-presentation__overlay').classList.remove('c-company-presentation__overlay--active')
      setTimeout(() => {
        document.querySelector('body').classList.remove('no-scroll')
        document.querySelector('html').classList.remove('no-scroll')
        this.currentCompany = null
      }, 500);
    },
  },
  beforeUnmount () {
    if (document.querySelector('body').classList.contains('no-scroll')) {
      document.querySelector('body').classList.remove('no-scroll')
    }
    if (document.querySelector('html').classList.contains('no-scroll')) {
      document.querySelector('html').classList.remove('no-scroll')
    }
  }
}
</script>