import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from '../../utils/splitText'
gsap.registerPlugin(ScrollTrigger);

class ScrollReveal {
  constructor () {
    this.init()
  }

  init () {
    const titles = document.getElementsByTagName('h2')

    for (let el of titles) {
      const title = new SplitText(el)
      gsap.from(title.chars, {
        scrollTrigger: {
          trigger: el,
          start: "top 70%",
        },
        duration: 0.6, 
        ease: "circ.out", 
        y: 80, 
        stagger: 0.02,
      });
    }
  }
}

export default ScrollReveal