class Navbar {
  constructor() {
    this.init()
  }
  init () {
    const navbar = document.querySelector('[data-navbar]')
    const header = document.querySelector('[data-navbar-header]')
    let prevScrollpos = window.pageYOffset;

    window.onscroll = function() {
      let currentScrollPos = window.pageYOffset;
      if (prevScrollpos < currentScrollPos && currentScrollPos > 200 && !navbar.classList.contains('c-navbar__nav--open')) {
        header.style.transform = "translateY(calc(-100% - 4rem))";
      } else {
        header.style.transform = "translateY(0)";
      }
      prevScrollpos = currentScrollPos;
    }
  }
}

export default Navbar