import POSTS from "@/constants/posts";
import COMMONS from '@/constants/commons'
import httpClient from "@/utils/httpClient";

const companys = {
  namespaced: true,
  state: {
    companys: []
  },
  getters: {
    majorCompanys (state) {
      return state.companys.filter(company => COMMONS.COMPANY_ARRAY.includes(company.slug))
    },
    secondaryCompanys (state) {
      return state.companys.filter(company => !COMMONS.COMPANY_ARRAY.includes(company.slug))
    }
  },
  mutations: {
    SET_ALL_COMPANYS (state, datas) {
      state.companys = datas
    }
  },
  actions: {
    async setAllCompanys ({commit}) {
      const response = await httpClient.get(POSTS.GET_COMPANYS)
      commit('SET_ALL_COMPANYS', response)
    }
  }
}

export default companys