<template>
	<div class="c-scrolldown">
		<span class="c-scrolldown__text">En savoir plus sur {{ title }}</span>
	</div>
</template>

<script>
export default {
	name: 'Scrolldown',
	props: {
		title: {
			type: String,
			default: 'rail open lab'
		}
	}
}
</script>