<template>
	<div v-if="img" class="c-video">
    <div class="c-video__visual">
      <button class="c-video__visual__btn" @click="playVideo">
        <span class="c-video__visual__btn__icon icon-play"></span>
      </button>
      <img :src="img.url" alt="" class="c-video__visual__img">
    </div>
    <iframe class="c-video__iframe" ref="video" width="1500" height="815" :src="url" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
	</div>
</template>

<script>

export default {
	name: 'Video',
  props: {
    img: {
      type: Number,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.setThumbnail()
  },
  methods: {
    playVideo() {
      let iframe = this.$el.querySelector('.c-video__iframe')
      let iframe_src = iframe.getAttribute('src')

      this.$el.querySelector('.c-video__visual').classList.add('c-video__visual--hide')
      iframe.setAttribute('src', `${iframe_src}?autoplay=1`)
    }
  }
}
</script>