<template>
	<div class="c-labels-list">
    <div class="c-labels-list__filters">
      <div class="c-labels-list__filters__container">
        <button 
          v-for="item, index in filters" 
          :key="index" 
          :data-labels-filters-item="item.id"
          class="c-labels-list__filters__item" 
          @click="handleInput"
        >
          {{ item.name }}
        </button>
      </div>
    </div>
    <div class="c-labels-list__container">
      <TransitionGroup name="fade">
        <div v-for="label, index in posts" :key="index" class="c-labels-list__item">
          <LabelItem :label="label" />
        </div>
      </TransitionGroup>
    </div>
  </div>
</template>

<script>
import LabelItem from './LabelItem.vue';

export default {
  name: "LabelsList",
  components: { LabelItem },
  props: {
    posts: {
        type: Array,
        required: true
    },
    filters: {
      type: Array,
      required: true
    }
  },
  methods: {
    handleInput (e) {
      let classActive = 'c-labels-list__filters__item--active'

      let siblings = e.target.parentNode.children
      for (let j = 0; j < siblings.length; j++ ) {
        if (siblings[j] !== e.target) {
          siblings[j].classList.remove(classActive)
        }
      }

      e.target.classList.toggle(classActive)

      e.target.classList.contains(classActive)
        ? this.$emit('setLabel', e.target.getAttribute('data-labels-filters-item'))
        : this.$emit('setLabel', '')
      
    }
  }
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: all .5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>