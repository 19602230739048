<template>
  <div 
    class="c-chip"
    :class="{'c-chip--dark': dark}"
    v-html="label"
  >
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    dark: {
      type: Boolean,
      default: false
    }
  }
}
</script>