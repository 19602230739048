<template>
  <div v-if="posts" class="carousel__container">
    <div class="carousel__container__svg">
      <ROLGradient />
    </div>
    <div class="carousel__wrapper">
      <div v-for="(post, index) in filteredPosts" :key="index" class="c-carousel__item">
        <SinglePreview :post="post" :context="context"/>
      </div>
    </div>
    <div v-if="ctaLabel && ctaLink" class="carousel__cta">
      <Cta 
        :label="ctaLabel"
        type="action"
        @click:button="$router.push(ctaLink)"
      />
    </div>
  </div>
</template>

<script>
import Cta from '@/components/globals/Cta'
import Carousel from '@/utils/Carousel'
import SinglePreview from '@/components/SinglePreview'
import ROLGradient from '@/components/vector/ROLGradient.vue';
export default {
  name: 'SinglePreviewCarousel',
  components: {
    Cta,
    SinglePreview,
    ROLGradient
},
  props: {
    posts: {
      type: Object,
      required: true
    },
    context: {
      type: String,
      default: ''
    },
    currentItem: {
      type: Number,
      default: null
    },
    ctaLabel: {
      type: String,
      default: ''
    },
    ctaLink: {
      type: String,
      default: ''
    }
  },
  computed: {
    filteredPosts () {
      if (this.currentItem) {
        return this.posts.filter(post => post.id !== this.currentItem)
      } else {
        return this.posts
      }
    }
  },
  mounted () {
    new Carousel(this.$el.querySelector('.carousel__wrapper'))
	}
}
</script>