<template>
    <svg :width="width" :height="height" :viewBox="`0 0 16 14`" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6666 14.0005H12.2286V9.07892C12.2286 7.79071 11.6909 6.91129 10.5083 6.91129C9.60375 6.91129 9.1007 7.50475 8.86657 8.07669C8.77876 8.28198 8.79249 8.56794 8.79249 8.85391V14.0005H5.38652C5.38652 14.0005 5.43042 5.28243 5.38652 4.48996H8.79249V5.98256C8.9937 5.32994 10.0821 4.39852 11.8189 4.39852C13.9737 4.39852 15.6666 5.76652 15.6666 8.71228V14.0005ZM1.94211 3.30036H1.92017C0.822647 3.30036 0.111084 2.57332 0.111084 1.65176C0.111084 0.712273 0.843679 0.000488281 1.96315 0.000488281C3.08171 0.000488281 3.76949 0.710489 3.79144 1.64908C3.79144 2.57064 3.08171 3.30036 1.94211 3.30036ZM0.503438 4.48996H3.53535V14.0005H0.503438V4.48996Z" :fill="color"/>
    </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 14
    },
    width: {
      type: Number,
      default: 16
    },
    color: {
      type: String,
      default: '#FFFFFF'
    }
  }
}
</script>