<template>
  <div class="page-spliter">
    <p>{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: 'PageSpliter',
  props: {
    title: {
      type: String,
      default: '',
      required: true
    }
  }
}
</script>