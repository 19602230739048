<template>
  <div class="c-single">
    <Banner
      :context="$const.COMMONS.BANNER_CONTEXT_POST_DETAILS"
      :image="getMedia(post.featured_media, post._embedded?.['wp:featuredmedia'])?.source_url"
    />
    <Intro
      :context="$const.COMMONS.ARCHIVE_CONTEXT_NEWS"
      :post="post"
    />
    <div class="c-single__container">
      <div class="c-single__content">
        <h3 class="c-title-section">Nos derniers articles</h3>
      </div>

      <SinglePreviewCarousel
        v-if="posts.length"
        :context="$const.COMMONS.ARCHIVE_CONTEXT_NEWS"
        :posts="posts"
        :currentItem="post.id"
      />
    </div>
  </div>
</template>

<script>
import Banner from '@/components/Banner.vue';
import Intro from '@/components/Intro.vue';
import SinglePreviewCarousel from '@/components/globals/carousel/SinglePreviewCarousel'
import { mapState } from 'vuex';
import { useMedia } from '@/composables/use-media-logic'

export default {
  name: "Single",
  setup () {
    const { getMedia } = useMedia()
    return { getMedia }
  },
  components: { Banner, Intro, SinglePreviewCarousel },
  computed: {
    ...mapState('posts', ['posts']),
    post() {
      if (this.posts && this.posts.length) {
        return this.posts.find(item => item.slug === this.$route.params.slug)
      } 
      return {}
    }
  }
}
</script>