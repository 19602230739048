import { ref, computed } from 'vue'
import COMMONS from '@/constants/commons'

export function usePaginations() {
  // DATA
  const currentPage = ref(1)

  // COMPUTED
  const firstKey = computed(() => {
    return currentPage.value === 1 ? 0 : (currentPage.value * COMMONS.ARCHIVE_PAGINATION_NUMBER) / 2
  })

  const secondKey = computed(() => {
    return currentPage.value * 10
  })

  // METHODS
  const updateCurrentPage = (value) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    currentPage.value = value
  }

  return {
    currentPage,
    firstKey,
    secondKey,
    updateCurrentPage
  }
}