<template>
  <div>
    <template v-if="type === $const.COMMONS.CTA.TYPE.LINK">
      <a
        v-if="target === '_blank'"
        :href="link"
        :target="target"
        class="cta"
        :class="{'filled': filled}"
      >
        <span>
          {{ label }}
        </span>
      </a>
      <router-link
        v-else
        class="cta"
        :to="linkToPage"
        :class="{'filled': filled}"
      >
        <span>
          {{ label }}
        </span>
      </router-link>
    </template>
    
    <button
      v-if="type === $const.COMMONS.CTA.TYPE.ACTION"
      class="cta"
      :class="{'filled': filled}"
      @click="$emit('click:button')"
    >
      <span>
        {{ label }}
      </span>
    </button>

    <button
      v-if="type === $const.COMMONS.CTA.TYPE.SUBMIT"
      class="cta"
      :class="{'filled': filled}"
      @click="$emit('click:button')"
      :type="type"
    >
      <span>
        {{ label }}
      </span>
    </button>
  </div>
</template>

<script>
import { useMenus } from '@/composables/use-menus-logic'

export default {
  setup () {
		const { parseNavBarUrl } = useMenus()

		return {
			parseNavBarUrl
		}
	},
  props: {
    type: {
      type: String,
      default: 'link'
    },
    label: {
      type: String,
      default: 'Devenir partenaire'
    },
    link: {
      type: String,
      default: ''
    },
    filled: {
      type: Boolean,
      default: false
    },
    target: {
      type: String,
      default: ''
    }
  },
  computed: {
    linkToPage() {
      if (this.target === "_blank") {
        return this.link
      }

      return this.parseNavBarUrl(this.link)
    }
  }
}
</script>