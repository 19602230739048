<template>
	<footer v-if="options && options.acf" class="c-footer">
		<Prefooter
			v-if="options.acf && options.acf.pre_footer"
			:title="options.acf.pre_footer.title"
			:content="options.acf.pre_footer.text"
		/>

		<div class="c-footer__content">
			<router-link 
				v-if="options.acf.pre_footer.link && options.acf.pre_footer.link.url" 
				:to="parseNavBarUrl(options.acf.pre_footer.link.url)" class="c-footer__content__cta"
			>
				<span class="c-footer__content__cta__label">
					{{ options.acf.pre_footer.link.title }}
				</span>
			</router-link>

			<div class="c-footer__cols">
				<div class="row">
					<div class="col-3-sm">
						<img src="@/assets/images/logo.svg" alt="">
					</div>
					<div v-if="items && items.length" class="col-3-sm">
						<div class="c-footer__cols__content">
							<router-link v-for="item, index in items" :key="index" :to="parseNavBarUrl(item.url)">{{item.title}}</router-link>
						</div>
					</div>
					<div class="col-3-sm">
						<div class="c-footer__cols__content">
							<a v-if="options.acf.footer_infos.phone" :href="`tel:${options.acf.footer_infos.phone}`">{{ options.acf.footer_infos.phone }}</a>
							<a v-if="options.acf.footer_infos.email" :href="`mailto:${options.acf.footer_infos.email}`">{{ options.acf.footer_infos.email }}</a>
							<p v-if="options.acf.footer_infos.address_line_1">{{ options.acf.footer_infos.address_line_1 }}</p>
							<p v-if="options.acf.footer_infos.address_line_2">{{ options.acf.footer_infos.address_line_2 }}</p>
						</div>
					</div>
					<div class="col-3-sm">
						<div class="c-footer__cols__content c-footer__cols__content--socials">
							<Socials
								v-if="options.acf.socials"
								:socials="options.acf.socials"
								:dark="false"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import Prefooter from '@/components/globals/Prefooter.vue'
import getFooter from '@/utils/FooterNav.js'
import Socials from '../Socials.vue'
import { useMenus } from '@/composables/use-menus-logic'

export default {
  name: 'Footer',
	setup () {
		const { parseNavBarUrl } = useMenus()

		return {
			parseNavBarUrl
		}
	},
	props: {
		options: {
			type: Object,
			default: () => ({})
		}
	},
  components: { Prefooter, Socials },
  data() {
		return {
				items: ''
		}
	},

	created() {
		this.getFooterItems()
	},
	methods: {
		async getFooterItems() {
			this.items = await getFooter()
		},
	}
}
</script>