/**
 	* @constant { number } slideToScroll Nombre d'éléments à faire défiler
 	*	@constant { number } slidesVisible Nombre d'éléments visible dans un slide
	*	@constant { Boolean } loop si l'on veut boucler au debut ou la fin du carousel
	* @constant { Boolean } pagination defini si l'on veut une pagination dans le carousel
	* @constant { Boolean } navigation 
	* @constant { Boolean } infinite Defini si l'on peux utiliser le carousel à l'infini
	* @type { object }
	* @default
 */
const defaultOptions = {
	slideToScroll: 1,
	slidesVisible: 1,
	loop: false,
	pagination: false,
	navigation: true,
	infinite: false
}

/**
 * @constant 
 * @type { number } defini la largeur de la fenetre minimum pour le mobile
 * @default
 */
const defaultWindowsWidth = 800

class Carousel {
	/**
	 *  @param { HtmlElement } element
	 * 	@param { Object } options
	 */
	constructor (element, options = {}) {
		this.element = element
		this.options = { ...defaultOptions, ...options }
		this.items = [].slice.call(element.children)
		this.isMobile = false
		this.currentItem = 0
		this.moveCallbacks = []
		this.offset = 0
		if (this.options.loop && this.options.infinite) {
			throw new Error("Impossible de mettre en loop et en infinite en même temps")
		}

		// Modification du DOM
		this.root = this.createDivWithClass('c-carousel')
		this.root.setAttribute('tabindex', '0')
		this.container = this.createDivWithClass('c-carousel__container')
		this.root.appendChild(this.container)
		this.element.appendChild(this.root)
		if (this.options.infinite) {
			this.offset = this.slidesVisible + this.slideToScroll
			this.items = [
				...this.items.slice(this.items.length - this.offset).map(item => item.cloneNode(true)),
				...this.items,
				...this.items.slice(0, this.offset).map(item => item.cloneNode(true)),
			]
			this.goToItem(this.offset, false)
		}
		this.items.forEach(item => this.container.appendChild(item));
		this.setStyle()
		if (this.options.navigation) this.createNavigation()
		if (this.options.pagination) this.createPagination()
		
		// Evenements
		this.moveCallbacks.forEach(cb => cb(this.currentItem))
		this.onWindowResize()
		window.addEventListener('resize', this.onWindowResize.bind(this))
		this.root.addEventListener('keyup', e => {
			if (e.key === 'ArrowRight' || e.key === 'Right') {
				this.next()
			} else if (e.key === 'ArrowLeft' || e.key === 'Left') {
				this.prev()
			}
		})
		if (this.options.infinite) this.container.addEventListener('transitionend', this.resetInifite.bind(this))
	}

	/**
	 * Applique les bonnes dimensions aux éléments du carousel
	 */
	setStyle () {
		const ratio = this.items.length / this.slidesVisible
		this.container.style.width = `${(ratio * 100)}%`
		this.items.forEach(item => item.style.width = `${(100 / this.slidesVisible) / ratio}%`);
	}

	/**
	 * Créer les boutons next/prev du carousel
	 */
	createNavigation () {
		const nextButton = this.createDivWithClass('c-carousel__next')
		const prevButton = this.createDivWithClass('c-carousel__prev')
		this.root.appendChild(nextButton)
		this.root.appendChild(prevButton)
		nextButton.addEventListener('click', this.next.bind(this))
		prevButton.addEventListener('click', this.prev.bind(this))
		if (this.options.loop) return
		this.onMove(index => {
			if (index === 0) {
				prevButton.classList.add('c-carousel__prev--hidden')
			} else {
				prevButton.classList.remove('c-carousel__prev--hidden')
			}
			if (this.items[this.currentItem + this.slidesVisible] === undefined) {
				nextButton.classList.add('c-carousel__next--hidden')
			} else {
				nextButton.classList.remove('c-carousel__next--hidden')
			}
		})
	}

	/**
	 * Créer la pagination dans le DOM
	 */
	createPagination () {
		const pagination = this.createDivWithClass('c-carousel__pagination')
		const buttons = []
		this.root.appendChild(pagination)
		for (let i = 0; i < (this.items.length - 2 * this.offset); i = i + this.slideToScroll) {
			let button = this.createDivWithClass('c-carousel__pagination__button')
			button.addEventListener('click', () => this.goToItem(i + this.offset))
			pagination.appendChild(button)
			buttons.push(button)
		}
		this.onMove(index => {
			const count = this.items.length - 2 * this.offset
			const activeButton = buttons[Math.floor((index - this.offset) % count / this.slideToScroll)]
			if (activeButton) {
				buttons.forEach(button => button.classList.remove('c-carousel__pagination__button--active'))
				activeButton.classList.add('c-carousel__pagination__button--active')
			}
		})
	}

	next () {
		this.goToItem(this.currentItem + this.slideToScroll)
	}

	prev () {
		this.goToItem(this.currentItem - this.slideToScroll)
	}

	/**
	 * Deplace le carousel vers l'élement ciblé
	 * @param { number } index
	 * @param { boolean } [animation = true]
	 */
	goToItem (index, animation = true) {
		if (index < 0) {
			if (this.options.loop) {
				index = this.items.length - this.slidesVisible
			} else {
				return
			}
		}
		if (index >= this.items.length || (this.items[this.currentItem + this.slidesVisible] === undefined && index > this.currentItem)) {
			if (this.options.loop) {
				index = 0
			} else {
				return
			}
		}
		const translateX = index * -100 / this.items.length
		if (animation === false) this.container.style.transition = 'none'
		this.container.style.transform = `translate3D(${translateX}%, 0, 0)`
		this.container.offsetHeight // force le repaint pour ne pas avoir d'animation au reload
		if (animation === false) this.container.style.transition = ''
		this.currentItem = index
		this.moveCallbacks.forEach(cb => cb(index))

	}

	/**
	 * Deplace le container pour donner l'impression d'un slide infini
	 */
	resetInifite () {
		if (this.currentItem <= this.slideToScroll) {
			this.goToItem(this.currentItem + (this.items.length - 2 * this.offset), false)
		} else if (this.currentItem >= this.items.length - this.offset) {
			this.goToItem(this.currentItem - (this.items.length - 2 * this.offset), false)
		}
	}

	/**
	 * 
	 * @param { Carousel~moveCallback } cb 
	 */
	onMove (cb) {
		this.moveCallbacks.push(cb)
	}

	/**
	 * 
	 */
	onWindowResize () {
		let mobile = window.innerWidth < defaultWindowsWidth
		if (mobile !== this.isMobile) {
			this.isMobile = mobile
			this.setStyle()
			this.moveCallbacks.forEach(cb => cb(this.currentItem))
		}
	}

	/**
	 * 
	 * @param { string } className
	 * @return { HTMLElement }
	 */
	createDivWithClass (className) {
		const div = document.createElement('div')
		div.setAttribute('class', className)
		return div
	}

	/**
	 * @return {number}
	 */
	get slideToScroll () {
		return this.isMobile ? 1 : this.options.slideToScroll
	}
	
	/**
	 * @return {number}
	 */
	get slidesVisible () {
		return this.isMobile ? 1 : this.options.slidesVisible
	}
}

export default Carousel