<template>
  <div class="c-content-image">
    <div class="c-content-image__visual">
      <img :src="image" alt="" class="c-content-image__visual__img" />
      <div class="c-content-image__visual__copyright">{{ copyright }}</div>
    </div>
    <div class="c-content-image__content">
      <span class="c-content-image__content__title h2-like">
        {{ title }}
      </span>
      <div v-if="content" class="c-content-image__content__text editor-block" v-html="content">
      </div>
      <Cta
        v-if="cta.title"
        type="link"
        :label="cta.title"
        :link="cta.url"
        :target="cta.target"
      />
      <div class="c-content-image__content__slot">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Cta from './Cta.vue';

export default {
    name: "ContentImage",
    components: { Cta },
    props: {
      title: {
        type: String,
        required: true
      },
      content: {
        type: String,
        default: ''
      },
      cta: {
        type: Object,
        default: () => ({})
      },
      image: {
        type: String,
        default: ''
      },
      copyright: {
        type: String,
        default: ''
      }
    }
}
</script>