<template>
	<div id="c-carousel" class="c-carousel--images">
		<div v-for="(slide, index) in slides" :key="index" class="c-carousel__item">
			<img :src="slide.source_url ?? slide.url" :alt="slide.title.rendered">
			<div class="c-carousel__item__copyright">{{ slide.caption }}</div>
		</div>
	</div>
</template>

<script>
import Carousel from '@/utils/Carousel'
export default {
	name: 'ImageCarousel',
	props: {
		slides: {
			type: Array,
			default: () => ([])
		}
	},
	data () {
		return {
			index: 0,
		}
	},
	mounted () {
		new Carousel(document.querySelector('#c-carousel'), {
			infinite: true
		})
	}
}
</script>