<template>
  <div class="c-formulaire">
    <div class="c-formulaire__message">
      <p v-if="errorMessage" class="formulaire__message__error">{{ errorMessage }}</p>
      <p v-if="successMessage" class="formulaire__message__success">{{ successMessage }}</p>
    </div>
    <form class="c-formulaire__container" @submit.prevent="checkForm">
      <div class="c-formulaire__field">
        <label for="input-1" class="c-formulaire__field__label">
          <span class="c-formulaire__field__label__text">Nom & prénom</span>
          <input v-model="fullname" name="fullname" id="input-1" type="text" placeholder="Nom & prénom"  class="c-formulaire__field__label__input" required/>
        </label>
      </div>
      <div class="c-formulaire__field">
        <label for="input-2" class="c-formulaire__field__label">
          <span class="c-formulaire__field__label__text">Entreprise</span>
          <input v-model="company" name="company" id="input-2" type="text" placeholder="Entreprise"  class="c-formulaire__field__label__input" required/>
        </label>
      </div>
      <div class="c-formulaire__field c-formulaire__field--wide">
        <label for="input-4" class="c-formulaire__field__label">
          <span class="c-formulaire__field__label__text">Email</span>
          <input v-model="email" name="email" id="input-4" type="email" placeholder="email"  class="c-formulaire__field__label__textarea" required/>
        </label>
      </div>
      <div class="c-formulaire__field c-formulaire__field--wide">
        <label for="input-4" class="c-formulaire__field__label">
          <span class="c-formulaire__field__label__text">Message</span>
          <textarea v-model="message" name="message" id="input-4" type="text" placeholder="Message"  class="c-formulaire__field__label__textarea" required/>
        </label>
      </div>
      <div class="c-formulaire__button">
        <Cta 
          label="Envoyer"
          :type="$const.COMMONS.CTA.TYPE.ACTION"
        />
      </div>
    </form>
  </div>
</template>

<script>
import Cta from './globals/Cta.vue';
import httpClient from '@/utils/httpClient.js';

export default {
    name: "BecamePartnerFormulaire",
    components: { Cta },
    data () {
      return {
        fullname: '',
        company: '',
        job: '',
        phone: '',
        email: '',
        message: '',
        successMessage: '',
        errorMessage: ''
      }
    },
    methods: {
      checkForm () {
        if (this.name === "" || this.company === "" || this.email === "" || this.message === "") {
          this.errorMessage = this.$const.COMMONS.FROM_FULLNAME_REQUIRED
        } else {
          this.submitForm()
        }
      },
      submitForm () {
        const datas = {
          "fullname": this.fullname,
          "company": this.company,
          "email": this.email,
          "message": this.message
        }

        const form = new FormData()
        for (const data in datas) {
          form.append(data, datas[data])
        }
        httpClient.post(`${this.$const.COMMONS.FORM_BASE_URL}/444/feedback`, form)
        .then((response) => {
          if (response.status === 'mail_sent') this.successMessage = response.message
          
        })
        .catch(() => {
          this.errorMessage = 'Une erreur est survenue'
        })
      }
    }
}
</script>