<template>
  <div
    class="c-preview"
    v-if="post"
    @click="blockLink"
  >
    <div class="c-preview__visual">
      <img
        :src="getMedia(post.featured_media, post._embedded?.['wp:featuredmedia'])?.source_url"
        :alt="post.title.rendered"
        class="c-preview__visual__img"
      />
      <p class="c-preview__visual__copyright">{{ post.acf.thumbnail_copyright }}</p>
      <ul
        v-if="context === $const.COMMONS.ARCHIVE_CONTEXT_REALISATIONS"
        class="c-preview__visual__chips"
      >
        <Chip
          :label="yearChip(post['annees-real'])"
        />
        <Chip
          v-for="(theme, index) in post['thematiques-real']"
          :key="index"
          :label="getRealisationThematique(theme)?.name"
        />
        <Chip
          v-for="(partenaire, index) in post['partenaires-real']"
          :key="index"
          :label="getRealisationPartenaire(partenaire)?.name"
          />
      </ul>
      <ul
        v-if="context === $const.COMMONS.ARCHIVE_CONTEXT_NEWS"
        class="c-preview__visual__chips"
      >
        <Chip
          v-for="(theme, index) in post['annees-post']"
          :key="index"
          :label="getPostAnnees(theme)"
        />
        <Chip
          v-for="(partenaire, index) in post.categories"
          :key="index"
          :label="getPostCategory(partenaire)"
          />
      </ul>
    </div>
    <div class="c-preview__content">
      <h3 class="c-preview__content__title" v-html="post.title.rendered || post.title"></h3>
      <div class="c-preview__content__tags">
        <template v-if="$const.COMMONS.ARCHIVE_CONTEXT_NEWS">
          <Tag
            v-for="(tag, index) in post.tags"
            :key="index"
            :label="getPostTag(tag)"
          />
        </template>
        <template v-if="$const.COMMONS.ARCHIVE_CONTEXT_REALISATIONS">
          <Tag
            v-for="(tag, index) in post['tags-real']"
            :key="index"
            :label="getRealisationsTag(tag)"
          />
        </template>
      </div>

      <div
        v-if="context === $const.COMMONS.ARCHIVE_CONTEXT_REALISATIONS" 
        class="c-preview__content__desc"
        v-html="post.excerpt.rendered"
      ></div>

      <template v-if="context === $const.COMMONS.ARCHIVE_CONTEXT_NEWS">
          <router-link 
            :to="`/actualites/${post.slug}`"
            class="c-preview__content__link"
          >
          Découvrir l'article
        </router-link>
      </template> 
      <template v-if="context === $const.COMMONS.ARCHIVE_CONTEXT_REALISATIONS">
        <router-link 
          :to="`/realisations/${post.slug}`"
          class="c-preview__content__link"
        >
        Découvrir l'article
      </router-link>
      </template>
    </div>
  </div>
</template>

<script>
import Chip from '@/components/globals/Chip.vue';
import Tag from '@/components/globals/Tag.vue';
import { mapState } from 'vuex'
import { useRealisation } from '@/composables/use-realisations-logic'
import { usePosts } from '@/composables/use-posts-logic'
import { useMedia } from '@/composables/use-media-logic'

// TO DO: Linker les tags
export default {
  name: "SinglePreview",
  setup () {
    const { getRealisationsTag, getRealisationThematique, getRealisationPartenaire, getRealisationAnnees, yearChip } = useRealisation()
    const { getPostAnnees, getPostCategory, getPostTag } = usePosts()
    const { getMedia } = useMedia()
    return {
      getRealisationsTag,
      getRealisationThematique,
      getRealisationPartenaire,
      getRealisationAnnees,
      getPostAnnees,
      getPostCategory,
      getPostTag,
      yearChip,
      getMedia
    }
  },
  components: { Chip, Tag },
  props: {
    post: {
      type: Object,
      required: true
    },
    context: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState('posts', [
      'posts_categories',
      'posts_annees'
    ]),
    ...mapState('realisations', [
      'realisations',
      'realisations_tags',
      'realisations_thematiques',
      'realisations_annees',
      'realisations_partenaires'
    ])
  },
  methods: {
    blockLink(e) {
      const link = this.$el.querySelector('.c-preview__content__link');
      if (
        (
          link.getAttribute('target') &&
          link.getAttribute('target') === '_blank'
        ) ||
        e.ctrlKey || e.button === 1
      ) {
        window.open(link.getAttribute('href'));
      } else if (e.button === 0) {
        document.location.href = link.getAttribute('href');
      }
    }
  }
}
</script>