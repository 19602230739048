import POSTS from "@/constants/posts";
import httpClient from "@/utils/httpClient";

const labels = {
  namespaced: true,
  state: {
    labels: [],
    labels_categories: [],
    labels_filters: {
      categories: ''
    }
  },
  getters: {
    getLabels (state) {
      return state.labels
    },
    filterByCategoriesLabels: (state) => (labels) => {
      return state.labels_filters.categories ? labels.filter(label => label.labels_categories.includes(parseInt(state.labels_filters.categories))) : labels
    },
    filteredLabels (state, getter) {
      return getter.filterByCategoriesLabels(state.labels)
    }
  },
  mutations: {
    SET_ALL_LABELS (state, datas) {
      state.labels = datas
    },
    SET_LABELS_CATEGORIES (state, datas) {
      state.labels_categories = datas
    },
    SET_LABEL_FILTERS (state, datas) {
      state.labels_filters[datas.field] = datas.value
    },
  },
  actions: {
    async setAllLabels ({commit}) {
      const response = await httpClient.get(POSTS.GET_LABELS)
      commit('SET_ALL_LABELS', response)
    },
    setLabelsFilters ({commit}, filter) {
      commit('SET_LABEL_FILTERS', filter)
    },
    async setLabelsCategories ({commit}) {
      const response = await httpClient.get(POSTS.GET_LABELS_CATEGORIES)
      commit('SET_LABELS_CATEGORIES', response)
    },
  }
}

export default labels