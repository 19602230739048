<template>
  <div>
    <Banner
      v-if="contact.acf"
      :context="$const.COMMONS.BANNER_CONTEXT_POSTS"
      :title="contact.acf.banner.title"
      :subtitle="contact.acf.banner.subtitle"
    />
    <BecamePartnerFormulaire />

    <ContentImage
      v-if="contact.acf && contact.acf.block_page?.content"
      :title="contact.acf.block_page.title"
      :content="contact.acf.block_page.content"
      :cta="contact.acf.block_page.link"
      :image="contact.acf.block_page.image.url"
    />
  </div>
</template>

<script>
import Banner from '@/components/Banner.vue';
import BecamePartnerFormulaire from '@/components/BecamePartnerFormulaire.vue';
import httpClient from '@/utils/httpClient';
import CONSTANTS from '@/constants';
import ContentImage from '@/components/globals/ContentImage.vue';

export default {
  name: "BecameParter",
  components: { Banner, BecamePartnerFormulaire, ContentImage },
  data() {
    return {
      contact: ''
    }
  },
  async mounted() {
    const response = await httpClient.get(`${CONSTANTS.PAGES.GET_PAGES}/?slug=devenir-partenaire&acf_format=standard&_embed`) // TO DO: Remplacer l'id par le slug
    this.contact = response[0]
  }
}

</script>