<template>
	<div class="c-pagination">
    <div class="c-pagination__content">
      <button
        class="c-pagination__content__control c-pagination__content__control--prev"
        @click="decrementPage"
      >
        <span class="c-pagination__content__control__icon icon-chevron-left"></span>
      </button>
      <div class="c-pagination__content__pages">
        <router-link 
          v-for="(page, index) in pages" :key="index"
          to="#" 
          class="c-pagination__content__pages__item"
          :class="{ 'c-pagination__content__pages__item--active': page === currentPage }"
          @click="updateCurrentPage(page)"
        >
          {{ page }}
        </router-link>
      </div>
      <button
        class="c-pagination__content__control c-pagination__content__control--next"
        @click="incrementPage"
      >
        <span class="c-pagination__content__control__icon icon-chevron-right"></span>
      </button>
    </div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'Pagination',
  props: {
    context: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentPage: 1
    }
  },
  computed: {
    ...mapGetters('posts', ['filteredPosts']),
    ...mapGetters('realisations', ['filteredRealisations']),
    pages () {
      if (this.context === this.$const.COMMONS.ARCHIVE_CONTEXT_REALISATIONS) {
        return Math.ceil(this.filteredRealisations.length / this.$const.COMMONS.ARCHIVE_PAGINATION_NUMBER)
      } else if (this.context === this.$const.COMMONS.ARCHIVE_CONTEXT_NEWS) {
        return Math.ceil(this.filteredPosts.length / this.$const.COMMONS.ARCHIVE_PAGINATION_NUMBER)
      }
      return 0
    }
  },
  methods: {
    updateCurrentPage (value) {
      this.currentPage = value
      this.$emit('updatePage', this.currentPage)
    },
    incrementPage () {
      this.currentPage++
      this.$emit('updatePage', this.currentPage)
    },
    decrementPage () {
      this.currentPage--
      this.$emit('updatePage', this.currentPage)
    }
  }
}
</script>