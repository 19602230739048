import { useStore } from 'vuex'

export function usePosts () {
  const store = useStore()

  // METHODS
  const getPostAnnees = (id) => {
    return store.getters['posts/getPostsAnnees'].find(item => item.id === id)?.name
  }

  const getPostCategory = (id) => {
    return store.getters['posts/getPostsCategories'].find(item => item.id === id)?.name
  }

  const getPostTag = (id) =>{
    return store.getters['posts/getPostsTags'].find(item => item.id === id)?.name
  }

  return {
    getPostAnnees,
    getPostCategory,
    getPostTag
  }
} 