<template>
    <div>
        <div class="c-navbar" data-navbar-header>
            <router-link to="/" class="c-navbar__logo">
                <LogoRailOpenLab />
            </router-link>
            <div class="c-navbar__nav" data-navbar>
                <div class="c-navbar__nav__mobile" data-navbar-burger @click="toggleMenuMobile">
                    <div class="c-navbar__nav__mobile__burger">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <span class="c-navbar__nav__mobile__label">Menu</span>
                </div>
                <div class="c-navbar__nav__container" data-navbar-container>
                    <ul v-if="items" class="c-navbar__nav__menu">
                        <li v-for="item in items" :key="item.ID">
                            <router-link 
                                :to="parseNavBarUrl(item.url)"
                                class="c-navbar__nav__menu__item" 
                                @click="toggleMenuMobile" 
                                data-navbar-item
                            >
                                {{item.title}}
                            </router-link>
                        </li>
                    </ul>
                    <div class="c-navbar__nav__socials">
                        <Socials
                            v-if="options && options.acf && options.acf.socials"
                            :socials="options.acf.socials"
                        />
                    </div>
                    <Cta
                        v-if="options && options.acf && options.acf.pre_footer && options.acf.pre_footer.link"
                        type="link"
                        :link="options.acf.pre_footer.link.url"
                        :label="options.acf.pre_footer.link.title"
                        filled
                        @click="closeMenuMobile"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import getNavbar from '@/utils/Navbar.js'
import Socials from '@/components/Socials.vue'
import Cta from '@/components/globals/Cta.vue'
import Navbar from '@/assets/js/navbar'
import LogoRailOpenLab from '@/components/vector/LogoRailOpenLab.vue'
import { useMenus } from '@/composables/use-menus-logic'

export default {
	name: 'Navbar',
	setup () {
		const { parseNavBarUrl } = useMenus()

		return {
			parseNavBarUrl
		}
	},
    props: {
        options: {
            type: Object,
			default: () => ({})
        }
    },
	components: { Socials, Cta, LogoRailOpenLab },
	data() {
		return {
			items: '',
		}
	},

	created() {
		this.getNavbarItems()
	},
	mounted() {
		new Navbar()
	},
	methods: {
		async getNavbarItems() {
			this.items = await getNavbar()
		},
    closeMenuMobile() {
        this.$el.querySelector('[data-navbar]').classList.remove('c-navbar__nav--open')
    },
    toggleMenuMobile() {
      const navbar = this.$el.querySelector('[data-navbar]')
      
      if (navbar && window.innerWidth < 992) {
        const container = navbar?.querySelector('[data-navbar-container]')
        
        navbar.classList.toggle('c-navbar__nav--open')

        this.__toggle = !this.__toggle
        this.__toggle
        ? container.style.height = `${container.scrollHeight + 30}px`
        : container.style.height = 0
      }
    }
	}
}
</script>