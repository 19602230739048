<template>
  <div>
    <Banner
      v-if="about && about.acf.banner"
      :context="$const.COMMONS.BANNER_CONTEXT_PAGE"
      :title="about.acf.banner.title"
      :subtitle="about.acf.banner.subtitle"
      :image="getMedia(about.featured_media, about._embedded['wp:featuredmedia'])?.source_url"
    />

    <Introduction
      v-if="about && about.acf"
      :title="about.acf.about_intro.title"
      :content="about.acf.about_intro.content"
      :cta="about.acf.about_intro.link"
      :image="about.acf.about_intro.image_arr"
      :copyright="about.acf.about_intro.introduction_copyright"
    >
      <Team
        v-if="about.acf && about.acf.about_head"
        :title="about.acf.about_head.title"
        :content="about.acf.about_head.text"
        :members="about.acf.about_head.members"
      />
    </Introduction>
    <TestimonialsCarousel 
      v-if="about.acf && about.acf.testimonies_page && testimonies" 
      :title="about.acf.testimonies_page_title ? about.acf.testimonies_page_title : 'Ils témoignent'"
      :slides="this.getTestimonies(about.acf.testimonies_page, testimonies)"
    />
    <div class="bg-grey-light">
      <div class="container">
        <Tabs
          v-if="about.acf && about.acf.about_theme.length"
          :tabs="about.acf.about_theme"
        />
      </div>
    </div>

    <LabelsList
      v-if="filteredLabels && filteredLabels.length"
      :posts="filteredLabels"
      :filters="labels_categories"
      @setLabel="setCategories"
    />

    <div 
      v-if="about.acf && about.acf.links_cloud_item.length"
      class="bg-grey-light"
    >
      <LinksCloud
        :links="about.acf.links_cloud_item"
      />
    </div>
  </div>
</template>

<script>
import Banner from '@/components/Banner.vue';
import TestimonialsCarousel from '@/components/globals/carousel/TestimonialsCarousel.vue';
import LinksCloud from '@/components/LinksCloud.vue';
import Introduction from '@/components/pages/Introduction.vue';
import Tabs from '@/components/Tabs.vue';
import Team from '@/components/Team.vue';
import httpClient from '@/utils/httpClient';
import CONSTANTS from '@/constants';
import { mapActions, mapState, mapGetters } from 'vuex';
import LabelsList from '@/components/LabelsList.vue';
import { useMedia } from '@/composables/use-media-logic';
import { useTestimonies } from '@/composables/use-testimonies-logic';
import ScrollReveal from '@/assets/js/scrollReveal'

export default {
  name: "About",
  setup () {
    const { getMedia } = useMedia()
    const { getTestimonies } = useTestimonies()
    return { getMedia, getTestimonies } 
  },
  components: { Banner, Introduction, Team, TestimonialsCarousel, Tabs, LinksCloud, LabelsList },
  data() {
    return {
      about: '',
      testimonies: null
    }
  },
  async created() {
    await this.setAllLabels()
    await this.setLabelsCategories()
  },
  computed: {
    ...mapGetters('labels', ['filteredLabels']),
    ...mapState('labels', ['labels_categories']),
  },
  async mounted() {
    const response = await httpClient.get(`${CONSTANTS.PAGES.GET_PAGES}?slug=presentation&acf_format=standard&_embed`)
    if (response && response.length) {
      this.about = response[0]
      this.testimonies = await httpClient.get(`${this.$const.POSTS.GET_TESTIMONIES}`)
    }
  },
  methods: {
    ...mapActions('labels', [
      'setAllLabels',
      'setLabelsCategories',
      'setLabelsFilters'
    ]),
    setCategories (value) {
      this.setLabelsFilters({field: 'categories', value: value})
    },
    onUpdated () {
      new ScrollReveal()
    }
  }
}
</script>