import POSTS from "@/constants/posts";
import httpClient from "@/utils/httpClient";

const realisations = {
  namespaced: true,
  state: {
    realisations: [],
    realisations_tags: [],
    realisations_thematiques: [],
    realisations_annees: [],
    realisations_partenaires: [],
    realisations_filters: {
      thematique : '',
      annee: '',
      partenaire: ''
    }
  },
  getters: {
    getAllRealisations (state) {
      return JSON.parse(JSON.stringify(state.realisations))
    },
    getRealisationsTags (state) {
      return state.realisations_tags
    },
    getRealisationsThematiques (state) {
      return state.realisations_thematiques
    },
    getRealisationsPartenaires (state) {
      return state.realisations_partenaires
    },
    getRealisationsAnnees (state) {
      return state.realisations_annees
    },
    filteredRealisations (state, getter) {
      return getter.filterByThematiques(getter.filterByPartenaire(getter.filterRealisationsByAnnee(state.realisations)))
    },
    filterByThematiques: (state) => (realisations) => {
      return state.realisations_filters.thematique ? realisations.filter(real => real['thematiques-real'].includes(parseInt(state.realisations_filters.thematique))) :realisations
    },
    filterRealisationsByAnnee: (state) => (realisations) => {
      const currentAnnees = state.realisations_annees.filter(item => item.name.substring(3) === state.realisations_filters.annee)
      return state.realisations_filters.annee ? realisations.filter(real => currentAnnees.some(item => real['annees-real'].includes(item.id))) : realisations
    },
    filterByPartenaire: (state) => (realisations) => {
      return state.realisations_filters.partenaire ? realisations.filter(real => real['partenaires-real'].includes(parseInt(state.realisations_filters.partenaire))) : realisations
    }
  },
  mutations: {
    SET_ALL_REALISATIONS(state, datas) {
      state.realisations = datas
    },
    SET_REALISATIONS_TAGS (state, datas) {
      state.realisations_tags = datas
    },
    SET_REALISATIONS_PARTENAIRES (state, datas) {
      state.realisations_partenaires = datas
    },
    SET_REALISATIONS_ANNEES (state, datas) {
      state.realisations_annees = datas
    },
    SET_REALISATIONS_THEMATIQUES (state, datas) {
      state.realisations_thematiques = datas
    },
    SET_REALISATION_FILTERS (state, datas) {
      state.realisations_filters[datas.field] = datas.value
    }
  },
  actions: {
    async setAllRealisations({commit}) {
      const response = await httpClient.get(POSTS.GET_ALL_REALISATIONS)
      commit('SET_ALL_REALISATIONS', response)
    },
    async setRealisationsTags ({commit}) {
      const response = await httpClient.get(POSTS.GET_REALISATIONS_TAGS)
      commit('SET_REALISATIONS_TAGS', response)
    },
    async setRealisationsAnnees ({commit}) {
      const response = await httpClient.get(POSTS.GET_REALISATIONS_ANNEES)
      commit('SET_REALISATIONS_ANNEES', response)
    },
    async setRealisationsPartenaires ({commit}) {
      const response = await httpClient.get(POSTS.GET_REALISATIONS_PARTENAIRES)
      commit('SET_REALISATIONS_PARTENAIRES', response)
    },
    async setRealisationsThematiques ({commit}) {
      const response = await httpClient.get(POSTS.GET_REALISATIONS_THEMATIQUES)
      commit('SET_REALISATIONS_THEMATIQUES', response)
    },
    setRealisationFilters ({commit}, filter) {
      commit('SET_REALISATION_FILTERS', filter)
    },
  }
}

export default realisations