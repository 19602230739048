<template>
  <div>
    <Navbar :options="options" />
    <router-view v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component"/>
      </transition>
    </router-view>
    <Footer v-if="currentRouteName !== NotFound" :options="options" />
    <Loader :visible="loading"/>
    <ModalAnalytics
      v-show="analyticsModalOpen"
      @enable:analytics="enableAnalytics"
      @disable:analytics="disableAnalytics"
    />
  </div>
</template>

<script>
import store from '@/store';
import { setupInterceptors } from '@/utils/httpInterceptors';
import { mapState, mapActions } from "vuex";
import Navbar from './components/globals/Navbar.vue'
import Loader from "@/views/Loader"
import Footer from '@/components/globals/Footer.vue';
import httpClient from '@/utils/httpClient';
import CONSTANTS from '@/constants';
import ModalAnalytics from '@/components/globals/ModalAnalytics.vue'

export default {
  name: 'App',
  components: { Navbar, Loader, Footer, ModalAnalytics },
  data() {
    return {
      options: '',
      analyticsModalOpen: false,
    }
  },
  async created() {
    setupInterceptors(store);
    this.setAllPosts()
    this.setAllRealisations()
    this.setAllCompanys()
    this.setAllPostsTags()
    this.setRealisationsTags()
    this.setRealisationsThematiques()
    this.setRealisationsAnnees()
    this.setRealisationsPartenaires()
    this.setPostsAnnees()
    await this.setPostsCategories()
  },
  computed: {
    ...mapState("loader", ["loading"]),
    currentRouteName() {
      return this.$route.name;
    }
  },
  async mounted() {
		const response = await httpClient.get(`${CONSTANTS.PAGES.GET_PAGES}?slug=page-options&acf_format=standard&_embed`)
    if (response && response.length) {
      this.options = response[0]
    }

if (this.getConsentCookie() === undefined) {
      this.analyticsModalOpen = true
    } else if (this.getConsentCookie() === true) {
      this.enableAnalytics()
    } else if (this.getConsentCookie() === false) {
      this.disableAnalytics()
    }
	},
  methods: {
    ...mapActions('posts', [
      'setPostsCategories',
      'setPostsAnnees',
      'setAllPosts',
      'setAllPostsTags'
    ]),
    ...mapActions('realisations', [
      'setAllRealisations',
      'setRealisationsTags',
      'setRealisationsAnnees',
      'setRealisationsPartenaires',
      'setRealisationsThematiques',
    ]),
    ...mapActions('companys', [
      'setAllCompanys'
    ]),
    enableAnalytics () {
      this.setConsentCookie(true)
      this.analyticsModalOpen = false
      this.$gtag.event('consent', {
        'ad_storage': 'granted',
        'analytics_storage': 'granted'
      })
    },
    disableAnalytics () {
      this.setConsentCookie(false)
      this.analyticsModalOpen = false
      this.$gtag.event('consent', {
        'ad_storage': 'denied',
        'analytics_storage': 'denied'
      })
    },
    setConsentCookie (boolean) {
      const date = new Date()
      date.setTime(date.getTime() + (100 * 24 * 60 * 60 * 1000))
      const expires = `; expires=${date.toUTCString()}`
      document.cookie = `consent_tracking=${boolean}${expires};path=/`
    },
    getConsentCookie () {
      let cookie = {};
      document.cookie.split(';').forEach(function(el) {
        let [key,value] = el.split('=');
        cookie[key.trim()] = value;
      })
      return cookie['consent_tracking'];
    }
  }
}
</script>


<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
